import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  DELETE_REQUESTS_LOCAL_DATA,
  UPDATE_REQUESTS_LOCAL_DATA,
  REQUESTS_DATA,
  SET_SORT,
  FETCH_REQUESTS_START,
  FETCH_REQUESTS_SUCCESS,
  SET_REQUESTS_API_PARAMS_OBJ,
} from "constants/ActionTypes";
import { REQUESTS_DATA_BY_ID } from "../../constants/ActionTypes";
import { DEFAULT_PAGE } from "../../constants/Common";
 
const INIT_STATE = {
  error: "",
  requestsLoading: false,
  apiParamsObj: {
    page:DEFAULT_PAGE
  },
  requestsCount: "",
  requestsData: [],
  sorterInput: "",
  requestsDataById:{},
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        error: "",
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: "",
      };
    }
    case FETCH_REQUESTS_START: {
      return {
        ...state,
        error: "",
        requestsLoading: true,
      };
    }
    case FETCH_REQUESTS_SUCCESS: {
      return {
        ...state,
        error: "",
        requestsLoading: false,
      };
    }
    case SET_SORT: {
      return {
        ...state,
        sorterInput: { ...action.payload },
      };
    }
    case SET_REQUESTS_API_PARAMS_OBJ: {
      return {
        ...state,
        apiParamsObj: action.payload,
      };
    }
    case REQUESTS_DATA: {
      return {
        ...state,
        error: "",
        requestsCount: action?.count,
        requestsData: action?.payload,
        requestsDataById:{}
      };
    }

    case REQUESTS_DATA_BY_ID: {
      return {
        ...state,
        error: "",
        requestsDataById: action.payload,
      };
    } 
    case UPDATE_REQUESTS_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        requestsCount: state.requestsCount - 1,
        requestsData: action.payload
      };
    }
    case DELETE_REQUESTS_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        requestsCount: state.requestsCount - 1,
        requestsData: action.payload,
      };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        error: action.payload, 
      };
    }
    default:
      return state;
  }
};
