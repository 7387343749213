import {
  CREATE_CATEGORIES_MUTATION,
  DELETE_CATEGORIES_MUTATION,
  UPDATE_CATEGORIES_MUTATION,
} from "../../appGraphQl/Mutation";
import { GET_CATEGORIES } from "../../appGraphQl/QUERIES";
import {
  ADD_LOCAL_DATA,
  UPDATE_CATEGORIES_LOCAL_DATA,
  DELETE_LOCAL_DATA,
  CATEGORIES_DATA,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  CATEGORIES_DROPDOWN,
} from "../../constants/ActionTypes";
import { displayMessage } from "../../util/renderMethod/displayMessage";

export const getCategoriesDropdown = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.query({
        query: GET_CATEGORIES,
        variables: { ...params },
      });
      if (data.getCategories) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: CATEGORIES_DROPDOWN, 
          payload: data.getCategories?.data,
        });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getCategories.error });
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
    }
  };
};

export const getCategories = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.query({
        query: GET_CATEGORIES,
        variables: { ...params },
      });
      if (data.getCategories) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: CATEGORIES_DATA,
          count: data.getCategories?.count,
          payload: data.getCategories?.data,
        });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getCategories.error });
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
    }
  };
};

export const createCategory = (params = {}, client) => {
  return async (dispatch) => {
    try {
      const { data } = await client.mutate({
        mutation: CREATE_CATEGORIES_MUTATION,
        variables: { ...params },
      });
      if (data?.createCategory) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: ADD_LOCAL_DATA,
          count: 1,
          payload: data.createCategory,
        });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.createCategory.error });
        return { type: FETCH_ERROR, payload: data.createCategory.error};
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const updateCategory = (params = {}, client) => {
  return async (dispatch) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_CATEGORIES_MUTATION,
        variables: { ...params, skip: 0, take: 10 },
      });
      if (data?.updateCategory) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: UPDATE_CATEGORIES_LOCAL_DATA,
          payload: data.updateCategory,
        });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.updateCategory.error });
        return {type: FETCH_ERROR, payload: data.updateCategory.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const deleteCategories = (params = {}, client) => {
  return async (dispatch) => {
    try {
      const { data } = await client.mutate({
        mutation: DELETE_CATEGORIES_MUTATION,
        variables: { ...params },
      });

      if (data?.deleteCategories) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DELETE_LOCAL_DATA, payload: params.categoriesIds[0] });
        displayMessage(FETCH_SUCCESS, data?.deleteCategories.message);
      } else {
        dispatch({ type: FETCH_ERROR, payload: false });
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      displayMessage(FETCH_ERROR, error.message);

      console.log("Error****:", error.message);
    }
  };
};
