import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import { CalendarOutlined, ClusterOutlined, FireOutlined, PullRequestOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons';

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);
  const authUser = useSelector(({ auth }) => auth.authUser);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.split("/")[1];
  const defaultOpenKeys = selectedKeys.split("/")[1];
  const { grantPermission, permission } = authUser || {}
  const { providers, events, categories, users, offers } = permission || {}
  const providersView = providers?.can_view || grantPermission
  const eventsView = events?.can_view || grantPermission
  const requestView = events?.can_view || grantPermission
  const categoriesView = categories?.can_view || grantPermission
  const usersView = users?.can_view || grantPermission
  const offersView = offers?.can_view || grantPermission
  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content" id="customMenuMask">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
            className="customMenu"
          >
            {providersView && <Menu.Item key="resources">
              <Link to="/resources">
                <ShopOutlined />
                <span className="menuTitle">
                  <IntlMessages id="sidebar.dashboard.providers" />
                </span>
              </Link>
            </Menu.Item>}
            {eventsView && <Menu.Item key="events">
              <Link to="/events">
                <CalendarOutlined />
                <span className="menuTitle">
                  <IntlMessages id="sidebar.dashboard.events" />
                </span>
              </Link>
            </Menu.Item>}
            {requestView && <Menu.Item key="requests">
              <Link to="/requests">
              <PullRequestOutlined />
                <span className="menuTitle">
                  <IntlMessages id="sidebar.dashboard.request_module" />
                </span>
              </Link>
            </Menu.Item>}
            {categoriesView && <Menu.Item key="category">
              <Link to="/category">
                <ClusterOutlined />
                <span className="menuTitle">
                  <IntlMessages id="sidebar.category" />
                </span>
              </Link>
            </Menu.Item>}
            {usersView && <Menu.Item key="users">
              <Link to="/users">
                <TeamOutlined />
                <span className="menuTitle">
                  <IntlMessages id="sidebar.users" />
                </span>
              </Link>
            </Menu.Item>}
            {offersView && <Menu.Item key="offers">
              <Link to="/offers">
                <FireOutlined />
                <span className="menuTitle">
                  <IntlMessages id="sidebar.offers" />
                </span>
              </Link>
            </Menu.Item>}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
