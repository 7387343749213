import {
  CREATE_USER_MUTATION,
  DELETE_USERS_MUTATION,
  UPDATE_USER_MUTATION,
} from "../../appGraphQl/Mutation";
import { GET_USERS } from "../../appGraphQl/QUERIES";
import {
  ADD_USERS_LOCAL_DATA,
  UPDATE_USERS_LOCAL_DATA,
  DELETE_USERS_LOCAL_DATA,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  USERS_DROPDOWN,
  USERS_DATA,
  SET_USERS_API_PARAMS_OBJ,
} from "../../constants/ActionTypes";
import { SORT_TYPE } from "../../constants/Common";
import {
  addLocalData,
  filterDeleteData,
  getPage,
  setPaginationForDelete,
} from "../../util/helper";
import { displayMessage } from "../../util/renderMethod/displayMessage";

export const getUserDropdown = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.query({
        query: GET_USERS,
        variables: { ...params },
      });
      if (data.getCategories) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: USERS_DROPDOWN,
          payload: data.getCategories?.data,
        });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getCategories.error });
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
    }
  };
};

export const getFilterObjectForUsers = (existParams = {}, values = {}) => {
  return async (dispatch) => {
    const { filter, searchTerm, sort } = values || {};
    let options = { ...existParams };
    let keys = Object.keys(filter || {});
    for (let filter of keys) {
      if (values.filter[filter]) {
        options[filter] = values.filter[filter];
      }
    }
    options["searchTerm"] = searchTerm;
    if (sort?.column && sort.sortType !== SORT_TYPE?.DEFAULT) {
      options["sort"] = { ...sort };
    } else {
      delete options.sort;
    }
    dispatch({ type: SET_USERS_API_PARAMS_OBJ, payload: values });
    return { status: true, data: { ...options } };
  };
};

export const getUsers = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.query({
        query: GET_USERS,
        variables: { ...params },
      });
      if (data.getUsers) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: USERS_DATA,
          count: data.getUsers?.count,
          payload: data.getUsers?.data,
        });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getUsers.error });
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
    }
  };
};

export const createUser = ({ params = {}, variables = {}, client }) => {
  return async (dispatch) => {
    try {
      const { getUsers } = client.cache.readQuery({
        query: GET_USERS,
        variables: { ...variables },
      });
      const { data } = await client.mutate({
        mutation: CREATE_USER_MUTATION,
        variables: { ...params },
        update(cache, { data }) {
          cache.writeQuery({
            query: GET_USERS,
            variables: { ...variables },
            data: {
              getUsers: {
                count: getUsers.count + 1,
                data: addLocalData({
                  data: getUsers.data,
                  newData: data.createUser.user,
                  pageLimit: params?.take,
                }),
              },
            },
          });
        },
      });
      if (data?.createUser) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: ADD_USERS_LOCAL_DATA,
          count: 1,
          payload: addLocalData({
            data: getUsers.data,
            newData: data.createUser.user,
            pageLimit: params?.take,
          }),
        });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.createUser.error });
        return { status: FETCH_ERROR, error: data.createUser.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const updateUser = (params = {}, client) => {
  return async (dispatch) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_USER_MUTATION,
        variables: { ...params, skip: 0, take: 10 },
      });
      if (data?.updateUser) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: UPDATE_USERS_LOCAL_DATA,
          payload: data.updateUser,
        });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.updateUser.error });
        return { status: FETCH_ERROR, error: data.updateUser.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const deleteUsers = ({ params = {}, variables = {}, client }) => {
  return async (dispatch) => {
    try {
      const { getUsers } = client.cache.readQuery({
        query: GET_USERS,
        variables: { ...variables },
      });
      const newDataList = filterDeleteData(
        getUsers.data,
        params.deleteUserIds[0]
      );
      let localUpdatedData = {};
      let isPageSet = false;
      if (newDataList?.length > 0 || getUsers?.count === 1) {
        localUpdatedData = newDataList;
      } else {
        const { getUsers } = client.cache.readQuery({
          query: GET_USERS,
          variables: {
            ...variables,
            ...setPaginationForDelete(variables?.skip, variables?.take),
          },
        });
        localUpdatedData = getUsers?.data;
        isPageSet = true;
      }

      const { data } = await client.mutate({
        mutation: DELETE_USERS_MUTATION,
        variables: { ...params },
        update(cache, { data }) {
          const { getUsers } = cache.readQuery({
            query: GET_USERS,
            variables: { ...variables },
          });
          cache.writeQuery({
            query: GET_USERS,
            variables: { ...variables },
            data: {
              getUsers: {
                count: getUsers.count - 1,
                data: [...localUpdatedData],
              },
            },
          });
        },
      });

      if (data?.deleteUsers) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DELETE_USERS_LOCAL_DATA, payload: localUpdatedData });
        displayMessage(FETCH_SUCCESS, data?.deleteUsers.message);
        return { status: isPageSet, page: getPage({ ...variables }) };
      } else {
        dispatch({ type: FETCH_ERROR, payload: false });
        return { status: FETCH_ERROR, error: data.deleteUsers.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      displayMessage(FETCH_ERROR, error.message);
      console.log("Error****:", error.message);
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};
