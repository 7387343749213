import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  UPDATE_LOAD_USER,
  AUTH_USER_DATA,
  USER_TOKEN_SET,
} from "../../constants/ActionTypes";
import axios from "util/Api";
import { FORGOT_PASSWORD_MUTATION, INITIAL_RESET_PASS_MUTATION, LOGIN_MUTATION, LOGOUT_MUTATION, RESET_PASSWORD_MUTATION } from "../../appGraphQl/Mutation";
import { GET_USERS_BY_TOKEN } from "../../appGraphQl/QUERIES";
import { ROLE_TYPE } from "../../constants/Common";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const userSignUp = ({ email, password, name }) => {
  console.log(email, password);
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/register", {
        email: email,
        password: password,
        name: name,
      })
      .then(({ data }) => {
        console.log("data:", data);
        if (data.result) {
          localStorage.setItem("token", data.token.access_token);
          axios.defaults.headers.common["authorization"] =
            "Bearer " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
          dispatch({ type: AUTH_USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const  userSignIn = (params, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.mutate({
        mutation: LOGIN_MUTATION,
        variables: { ...params },
      });
      if (data.login) {
        localStorage.setItem("token", data.login.accessToken);
        let payloadData = {
          ...data.login.user,
          requirePasswordChange: data?.login?.requirePasswordChange,
          permission: {
            ...ROLE_TYPE[data.login.user.role],
            grantPermission: data.login.user === ROLE_TYPE.SUPER_ADMIN.value,
          },
        };
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: data.login.accessToken });
        // dispatch({ type: AUTH_USER_DATA, payload: { ...payloadData } });
        return {
          status: true,
          response: { ...payloadData },
          token: data.login.accessToken,
        };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
        return { status: false, error: data.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
      return { status: false, error: error.message };
    }
  };
};

export const  userInitialPassReset = (params, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.mutate({
        mutation: INITIAL_RESET_PASS_MUTATION,
        variables: { ...params },
      });
      if (data.initialPasswordReset?.user?.is_password_changed) {
        localStorage.removeItem("token");
        client.resetStore();
        dispatch({ type: FETCH_SUCCESS });
        return {
          status: true,
          response: { ...data.initialPasswordReset },
        };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
        return { status: false, error: data.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
      return
      // return { status: false, error: error.message };
    }
  };
};

export const forgotPasswordSendEmail = (email, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.mutate({
        mutation: FORGOT_PASSWORD_MUTATION,
        variables: { email },
      });
      if (data?.requestPasswordReset?.status) {
        dispatch({ type: FETCH_SUCCESS });
        return {
          status: true,
          message: data.requestPasswordReset.message,
        };
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: data.requestPasswordReset.message,
        });
        return {
          status: false,
          message: data.requestPasswordReset.message,
        };
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log('Error****:', error.message);
      return {
        status: false,
        error: error.message,
      };
    }
  };
};

export const resetPassword = (params, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.mutate({
        mutation: RESET_PASSWORD_MUTATION,
        variables: { ...params },
      });
      if (data?.resetPassword?.user?.is_password_changed) {
        localStorage.removeItem('token');
        client.resetStore();
        dispatch({ type: FETCH_SUCCESS });
        return {
          status: true,
          respose: {
            ...data.resetPassword,
          },
        };
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: data.error,
        });
        return {
          status: false,
          message: data.error,
        };
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log('Error****:', error.message);
      return {
        status: false,
        message: error.message,
      };
    }
  };
};

export const getUserByToken = (token, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { error, data } = await client.query({
        query: GET_USERS_BY_TOKEN,
      });
      if (data?.me) {
        const { user = {}, extras = {} } = data?.me || {};
        if((user?.role === 'TRUSTED_PARTNER' || user?.role === 'VOLUNTEER') && !user?.is_password_changed) throw new Error('Password is not changed. Please change password first')
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: AUTH_USER_DATA,
          payload: {
            ...user,
            extras: { ...extras },
            permission: ROLE_TYPE[user.role],
            grantPermission: user === ROLE_TYPE.SUPER_ADMIN.value,
          },
        });
      } else {
        dispatch({ type: FETCH_ERROR, payload: error });
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: UPDATE_LOAD_USER, payload: false });
      console.log("Error****:", error.message);
    }
  };
};

export const userSignOut = ({ client }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.mutate({
        mutation: LOGOUT_MUTATION,
      });
      if (data?.logout?.status) {
        localStorage.removeItem('token');
        client.resetStore();
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SIGNOUT_USER_SUCCESS });
        return { status: true };
      }
      dispatch({ type: FETCH_ERROR, payload: data?.errors.message });
      return { status: true };
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: UPDATE_LOAD_USER, payload: false });
      console.log("Error****:", error.message);
      return { status: false };
    }
  };
};
