import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  CATEGORIES_DATA,
  SET_SORT,
  ADD_LOCAL_DATA,
  CATEGORIES_DROPDOWN,
  UPDATE_CATEGORIES_LOCAL_DATA,
  DELETE_LOCAL_DATA,
} from "constants/ActionTypes";

const INIT_STATE = {
  error: "",
  categoriesLoading: false,
  categoriesCount: "",
  categoriesData: [],
  categoriesDropdownList: [],
  sorterInput: "",
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        error: "",
        categoriesLoading: true,
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: "",
        categoriesLoading: false,
      };
    }
    case CATEGORIES_DROPDOWN: {
      return {
        ...state,
        error: "",
        categoriesDropdownList: action.payload,
        categoriesLoading: false,
      };
    }
    case SET_SORT: {
      return {
        ...state,
        sorterInput: { ...action.payload },
      };
    }
    case CATEGORIES_DATA: {
      return {
        ...state,
        error: "",
        categoriesCount: action.count,
        categoriesData: action.payload,
        categoriesLoading: false,
      };
    }
    case ADD_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        categoriesCount: state.categoriesCount + action.count,
        categoriesData: [...state.categoriesData, { ...action.payload }],
        categoriesLoading: false,
      };
    }
    case UPDATE_CATEGORIES_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        categoriesData: state.categoriesData?.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        ),
        categoriesLoading: false,
      };
    }
    case DELETE_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        categoriesCount: state.categoriesCount - 1,
        categoriesData: state.categoriesData.filter(
          (item) => item.id !== action.payload
        ),
        categoriesLoading: false,
      };
    }

    case FETCH_ERROR: {
      return {
        ...state,
        error: action.payload,
        categoriesLoading: false,
      };
    }
    default:
      return state;
  }
};
