import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.path}events/:eventId`}
        component={asyncComponent(() =>
          import("../components/Events/EventDetails")
        )}
      />
       <Route
        path={`${match.path}requests/event/:eventId`}
        component={asyncComponent(() =>
          import("../components/Events/EventDetails")
        )}
      />
      <Route
        path={`${match.url}events`}
        component={asyncComponent(() => import("./EventPage"))}
      />
       <Route
        path={`${match.path}requests/:providerId`}
        component={asyncComponent(() =>
          import("../components/Providers/AddProvider")
        )}
      /> 
      <Route
        path={`${match.url}requests`}
        component={asyncComponent(() => import("./RequestsPage"))}
      />
      
      <Route
        path={`${match.path}resources/:providerId`}
        component={asyncComponent(() =>
          import("../components/Providers/AddProvider")
        )}
      />
      <Route
        path={`${match.url}resources`}
        component={asyncComponent(() => import("./Providers"))}
      />
      <Route
        path={`${match.url}category`}
        component={asyncComponent(() => import("./Categories"))}
      />
      <Route
        path={`${match.url}users`}
        component={asyncComponent(() => import("./UsersPage"))}
      />
      <Route
        path={`${match.url}offers`}
        component={asyncComponent(() => import("./OffersPage"))}
      />

      <Route
        path={`${match.url}sample`}
        component={asyncComponent(() => import("./SamplePage"))}
      />
    </Switch>
  </div>
);

export default App;
