import {
  ACTIVE_STATUS_RESOURCE_MUTATION,
  CREATE_RESOURCE_MUTATION,
  DELETE_RESOURCE_MUTATION,
  UPDATE_RESOURCE_MUTATION,
  UPDATE_RESOURCE_MUTATION_TP,
} from "../../appGraphQl/Mutation";
import {
  GET_ACCESSIBLITY_DROPDOWN,
  GET_AGE_REQUIREMENTS,
  GET_APPLICATION_PROCESS_DROPDOWN,
  GET_DOCUMENTS_REQUIRED_DROPDOWN,
  GET_ELIGIBILITIES_DROPDOWN,
  GET_LANGUAGES_DROPDOWN,
  GET_LIMITS_PERVISIT_DROPDOWN,
  GET_PROVIDERS,
} from "../../appGraphQl/QUERIES";
import {
  ADD_PROVIDER_LOCAL_DATA,
  DELETE_PROVIDER_LOCAL_DATA,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  AGE_REQUIREMENTS_DROPDOWN,
  PROVIDER_DATA,
  PROVIDER_DATA_BY_ID,
  UPDATE_PROVIDER_LOCAL_DATA,
  DOCUMENTS_REQUIRED_DROPDOWN,
  ACCESSIBLITY_DROPDOWN,
  APPLICATION_PROCESS_DROPDOWN,
  ELIGIBILITIES_DROPDOWN,
  LIMITS_PERVISIT_DROPDOWN,
  SET_PROVIDER_API_PARAMS_OBJ,
  FETCH_PROVIDERS_START,
  FETCH_EXPORT_START,
  FETCH_PROVIDER_BY_ID_START,
  LIMITS_LANGUAGES_DROPDOWN,
} from "../../constants/ActionTypes";
import { DEFAULT_SORT_BY_TIME, SORT_TYPE } from "../../constants/Common";
import {
  filterDeleteData,
  setPaginationForDelete,
  getPage,
  addLocalData,
  exportFile,
  exportDownload,
} from "../../util/helper";
import { displayMessage } from "../../util/renderMethod/displayMessage";

export const getFilterObjectForProviders = (existParams = {}, values = {}) => {
  return async (dispatch) => {
    const { filter, searchTerm, sort } = values || {};
    let options = { ...existParams };
    let keys = Object.keys(filter || {});
    for (let filter of keys) {
      if (values.filter[filter]?.length > 0) {
        options[filter] = values.filter[filter];
      } else {
        delete options[filter];
      }
    }
    options["searchTerm"] = searchTerm;
    if (sort?.column && sort.sortType !== SORT_TYPE?.DEFAULT) {
      options["sort"] = { ...sort };
    } else {
      options["sort"] = { ...DEFAULT_SORT_BY_TIME };
    }
    dispatch({ type: SET_PROVIDER_API_PARAMS_OBJ, payload: { ...options } });
    return { status: true, data: { ...options } };
  };
};

export const getResources = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_PROVIDERS_START });
      const { data } = await client.query({
        query: GET_PROVIDERS,
        variables: { ...params },
      });
      if (data?.getResources) {
        dispatch({
          type: PROVIDER_DATA,
          count: data.getResources?.count,
          payload: data.getResources?.data,
        });
        return {
          status: true,
          count: data.getResources?.count,
        };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getResources.error });
        return {
          status: false,
          error: data.getResources.error,
        };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return {
        status: false,
        error: error.message,
      };
    }
  };
};
// this method is used
export const exportResourcesFile = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_EXPORT_START });
      let url = `type=resources`;
      const { data } = await exportFile({
        url,
        params,
      });
      exportDownload({ url: data?.url, fileName: "resources-list" });
      return { status: true, count: data };
    } catch (error) {
      console.log("Error****:", error.message);
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({ type: FETCH_ERROR, payload: false });
      return { status: false, error: error.message };
    }
  };
};

export const getAgeRequirementsDropdown = (params = {}, client) => {
  return async (dispatch) => {
    try {
      const { data } = await client.query({
        query: GET_AGE_REQUIREMENTS,
        // variables: { ...params },
      });
      if (data.getAgeRequirements) {
        dispatch({
          type: AGE_REQUIREMENTS_DROPDOWN,
          payload: data.getAgeRequirements,
        });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getAgeRequirements.error });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
    }
  };
};

export const getDocumentsRequiredDropdown = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.query({
        query: GET_DOCUMENTS_REQUIRED_DROPDOWN,
        // variables: { ...params },
      });
      if (data.getDocumentsRequired) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: DOCUMENTS_REQUIRED_DROPDOWN,
          payload: data.getDocumentsRequired,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: data.getDocumentsRequired.error,
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
    }
  };
};

export const getAccessiblityDropdown = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.query({
        query: GET_ACCESSIBLITY_DROPDOWN,
        // variables: { ...params },
      });
      if (data.getAccessibility) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: ACCESSIBLITY_DROPDOWN,
          payload: data.getAccessibility,
        });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getAccessibility.error });
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
    }
  };
};

export const getApplicationProcessDropdown = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.query({
        query: GET_APPLICATION_PROCESS_DROPDOWN,
        // variables: { ...params },
      });
      if (data.getApplicationProcess) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: APPLICATION_PROCESS_DROPDOWN,
          payload: data.getApplicationProcess,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: data.getApplicationProcess.error,
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
    }
  };
};

export const getEligibilitiesDropdown = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.query({
        query: GET_ELIGIBILITIES_DROPDOWN,
        // variables: { ...params },
      });
      if (data.getEligibilities) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: ELIGIBILITIES_DROPDOWN,
          payload: data.getEligibilities,
        });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getEligibilities.error });
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
    }
  };
};

export const getLimitsPerVisitDropdown = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.query({
        query: GET_LIMITS_PERVISIT_DROPDOWN,
        // variables: { ...params },
      });
      if (data.getLimitsPerVisit) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: LIMITS_PERVISIT_DROPDOWN,
          payload: data.getLimitsPerVisit,
        });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getLimitsPerVisit.error });
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
    }
  };
};

export const getLanguagesDropdown = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.query({
        query: GET_LANGUAGES_DROPDOWN,
        // variables: { ...params },
      });
      if (data.getLanguages) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: LIMITS_LANGUAGES_DROPDOWN,
          payload: data.getLanguages,
        });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getLanguages.error });
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
    }
  };
};

export const getResourcesById = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_PROVIDER_BY_ID_START });
      const { data } = await client.query({
        query: GET_PROVIDERS,
        variables: { ...params },
      });

      if (data?.getResources) {
        dispatch({
          type: PROVIDER_DATA_BY_ID,
          payload: data.getResources?.data[0],
        });
        return { status: true, data: data.getResources?.data[0] };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getResources.error });
        return { status: false, error: data.getResources.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: false, error: error.message };
    }
  };
};

export const createResources = ({ params = {}, variables = {}, client }) => {
  return async (dispatch) => {
    try {
      const { getResources = {} } =
        (await client.cache.readQuery({
          query: GET_PROVIDERS,
          variables: { ...variables },
        })) || {};
      const { data } = await client.mutate({
        mutation: CREATE_RESOURCE_MUTATION,
        variables: { ...params },
        update(cache, { data }) {
          cache.writeQuery({
            query: GET_PROVIDERS,
            variables: { ...variables },
            data: {
              getResources: {
                count: getResources.count + 1,
                data: addLocalData({
                  data: getResources.data,
                  newData: data.createResource,
                  pageLimit: params?.take,
                  firstIn: true,
                }),
              },
            },
          });
        },
        // refetchQueries: [{ query: GET_PROVIDERS, variables: { ...variables } }],
      });

      if (data?.createResource) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: ADD_PROVIDER_LOCAL_DATA,
          count: 1,
          payload: addLocalData({
            data: getResources.data,
            newData: data.createResource,
            pageLimit: params?.take,
            firstIn: true,
          }),
        });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.createResource.error });
        return { status: FETCH_ERROR, error: data.createResource.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const updateResources = (params = {}, client) => {
  return async (dispatch) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_RESOURCE_MUTATION,
        variables: { ...params, skip: 0, take: 10 },
      });
      if (data?.updateResource) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: UPDATE_PROVIDER_LOCAL_DATA,
          payload: data.updateResource,
        });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.updateResource.error });
        return { status: FETCH_ERROR, error: data.updateResource.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const deleteResource = ({ params = {}, variables = {}, client }) => {
  return async (dispatch) => {
    try {
      const resourcesData = await client.cache.readQuery({
        query: GET_PROVIDERS,
        variables: { ...variables },
      });
      const newDataList = filterDeleteData(
        resourcesData?.getResources.data,
        params.deleteResourceIds[0]
      );
      let localUpdatedData = {};
      let isPageSet = false;
      if (newDataList?.length > 0 || resourcesData?.getResources?.count === 1) {
        localUpdatedData = newDataList;
      } else {
        const data = client.cache?.readQuery({
          query: GET_PROVIDERS,
          variables: {
            ...variables,
            ...setPaginationForDelete(variables?.skip, variables?.take),
          },
        });
        localUpdatedData =
          data !== null ? data?.getResources?.data : newDataList;
        isPageSet = true;
      }
      const { data } = await client.mutate({
        mutation: DELETE_RESOURCE_MUTATION,
        variables: { ...params },
        update(cache, { data }) {
          cache.writeQuery({
            query: GET_PROVIDERS,
            variables: { ...variables },
            data: {
              getResources: {
                count: resourcesData?.getResources.count - 1,
                data: localUpdatedData,
              },
            },
          });
        },
      });

      if (data?.deleteResources) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: DELETE_PROVIDER_LOCAL_DATA,
          payload: localUpdatedData,
        });
        displayMessage(FETCH_SUCCESS, data?.deleteResources.message);
        return { status: isPageSet, page: getPage({ ...variables }) };
      } else {
        dispatch({ type: FETCH_ERROR, payload: false });
        return { status: FETCH_ERROR, error: data.deleteResource.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      displayMessage(FETCH_ERROR, error.message);
      console.log("Error****:", error.message);
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const updateResourcesTP = (params = {}, client) => {
  return async (dispatch) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_RESOURCE_MUTATION_TP,
        variables: { ...params, skip: 0, take: 10 },
      });
      if (data?.updateResourceTp) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: UPDATE_PROVIDER_LOCAL_DATA,
          payload: data.updateResourceTp,
        });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.updateResourceTp.error });
        return { status: FETCH_ERROR, error: data.updateResourceTp.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const resourceActiveStatus = (params = {}, client) => {
  return async (dispatch) => {
    try {
      const { data } = await client.mutate({
        mutation: ACTIVE_STATUS_RESOURCE_MUTATION,
        variables: { ...params },
      });

      if (data?.updateResource) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: UPDATE_PROVIDER_LOCAL_DATA,
          payload: data.updateResource,
        });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.updateResource.error });
        return { status: FETCH_ERROR, error: data.updateUser.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      displayMessage(FETCH_ERROR, error.message);

      console.log("Error****:", error.message);
    }
  };
};

export const getResourcesS = ({ getResources }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      if (getResources) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: PROVIDER_DATA,
          count: getResources?.count,
          payload: getResources?.data,
        });
      } else {
        dispatch({ type: FETCH_ERROR, payload: getResources.error });
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
    }
  };
};
