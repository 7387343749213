// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "success";
export const FETCH_ERROR = "error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const AUTH_USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";
export const UPDATE_LOAD_USER = "UPDATE_LOAD_USER";

// common
export const SET_API_PARAMS_OBJ = "set_api_params_obj";
export const SET_SORT = "set_sort";
export const EXPORT_DATA = "export_data";
export const FETCH_EXPORT_START = "providers_export_fetch_start";
export const FETCH_EXPORT_SUCCESS = "providers_export_success";

// PAGES
export const FETCH_PROVIDERS_START = "providers_fetch_start";
export const FETCH_PROVIDERS_SUCCESS = "providers_success";
export const PROVIDER_DATA = "providers_data";
export const SET_PROVIDER_API_PARAMS_OBJ = "set_provider_api_params_obj";
export const ADD_PROVIDER_LOCAL_DATA = "add_provider_local_data";
export const UPDATE_PROVIDER_LOCAL_DATA = "update_provider_local_data";
export const DELETE_PROVIDER_LOCAL_DATA = "delete_provider_local_data";
export const FETCH_PROVIDER_BY_ID_START = "provider_by_id_fetch_start";
export const FETCH_PROVIDER_BY_ID_SUCCESS = "provider_by_id_success";
export const PROVIDER_LOADER_FOR_CURD_START = "provider_loader_curd_start";
export const PROVIDER_LOADER_FOR_CURD_SUCCESS = "provider_loader_curd_success";
export const PROVIDER_DATA_BY_ID = "providers_data_by_id";
export const CATEGORIES_DATA = "categories_data";
export const COUNTY_DATA = 'county_data';
export const UPDATE_CATEGORIES_LOCAL_DATA = "update_categories_local_data";
export const UPDATE_COUNTY_LOCAL_DATA = "update_county_local_data";
export const CATEGORIES_DROPDOWN = "categories_dropdown";
export const COUNTY_DROPDOWN = 'county_dropdown';
export const USERS_DATA = "users_data";
export const SET_USERS_API_PARAMS_OBJ = "set_users_api_params_obj";
export const ADD_USERS_LOCAL_DATA = "add_users_local_data";
export const UPDATE_USERS_LOCAL_DATA = "update_users_local_data";
export const DELETE_USERS_LOCAL_DATA = "delete_users_local_data";
export const USERS_DROPDOWN = "users_dropdown";
export const ADD_LOCAL_DATA = "add_local_data";
export const UPDATE_LOCAL_DATA = "update_local_data";
export const UPDATE_LOCAL_PRIORITY_DATA = "update_local_priority_data";
export const DELETE_LOCAL_DATA = "delete_local_data";
export const FETCH_EVENTS_START = "events_fetch_start";
export const FETCH_EVENTS_SUCCESS = "events_success";
export const EVENTS_DATA = "events_data";
export const SET_EVENTS_API_PARAMS_OBJ = "set_events_api_params_obj";
export const ADD_EVENT_LOCAL_DATA = "add_event_local_data";
export const UPDATE_EVENT_LOCAL_DATA = "update_event_local_data";
export const DELETE_EVENT_LOCAL_DATA = "delete_event_local_data";
export const FETCH_EVENTS_BY_ID_START = "events_by_id_fetch_start";
export const FETCH_EVENTS_BY_ID_SUCCESS = "events_by_id_success";
export const EVENTS_DATA_BY_ID = "events_data_by_id";
export const EVENT_CATEGORIES_DROPDOWN = "event_categories_dropdown";
export const FETCH_REQUESTS_START = "requests_fetch_start";
export const FETCH_REQUESTS_SUCCESS = "requests_success";
export const REQUESTS_DATA = "requests_data";
export const ADD_REQUESTS_LOCAL_DATA = "add_requests_local_data";
export const REQUESTS_DATA_BY_ID = "requests_data_by_id";
export const SET_REQUESTS_API_PARAMS_OBJ = "set_requests_api_params_obj";
export const UPDATE_REQUESTS_LOCAL_DATA = "update_requests_local_data";
export const DELETE_REQUESTS_LOCAL_DATA = "delete_requests_local_data";
export const AGE_REQUIREMENTS_DROPDOWN = "age_requirements_dropdown";
export const DOCUMENTS_REQUIRED_DROPDOWN = "documents_required_dropdown";
export const ACCESSIBLITY_DROPDOWN = "accessiblity_dropdown";
export const APPLICATION_PROCESS_DROPDOWN = "application_process_dropdown";
export const ELIGIBILITIES_DROPDOWN = "eligibilities_dropdown";
export const LIMITS_PERVISIT_DROPDOWN = "limits_pervisit_dropdown";
export const LIMITS_LANGUAGES_DROPDOWN = "limits_languages_dropdown";
export const OFFERS_DATA_LIST = "offers_data_list";
export const ADD_OFFERS_LOCAL_DATA = "add_offers_local_data";
export const UPDATE_OFFERS_LOCAL_DATA = "update_offers_local_data";
export const DELETE_OFFERS_LOCAL_DATA = "delete_offers_local_data";
export const STORE_LOCAL_COUNTY_FOR_PROVIDER_FILTER = 'store_local_county_for_provider_filter'
export const SET_ARCHIVE_REQ = 'SET_ARCHIVE_REQ';