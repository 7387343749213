import { isArray } from "lodash-es";
import moment from "moment";
import { VALIDATION, PATTERN } from "./../constants/Common";
import IntlMessages from "./IntlMessages";
import { Option } from "antd/lib/mentions";

export function checkPattern(value, pattern) {
  if (!pattern) {
    return true;
  }
  return pattern?.test(value || "");
}

export const findDigitsCount = (value) => {
  let counter = 0;
  value = value || "";
  for (let character of value) {
    if (!isNaN(Number(character))) {
      counter += 1;
    }
  }
  return counter;
};

export function checkIfValidEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validateEmailMessage = (message) => (rule, value, callback) => {
  if (value) {
    if (!checkPattern(value, PATTERN.WITHOUT_SPACE_BEGINNING)) {
      return callback(<IntlMessages id="message.space_not_allowed" />);
    } else if (!checkIfValidEmail(value.trim())) {
      callback(message || <IntlMessages id="message.valid_email" />);
    }
  }
  callback();
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
};

export const phoneNumberValidation = (rule, value, callback) => {
  if (value) {
    const digits = findDigitsCount(value);
    if (!checkPattern(value, PATTERN.WITHOUT_SPACE_BEGINNING)) {
      callback(<IntlMessages id="message.space_not_allowed" />);
      return {
        status: false,
        error: <IntlMessages id="message.space_not_allowed" />,
      };
    }
    if (!checkPattern(value, PATTERN.PHONE_NUMBER_FORMAT)) {
      callback(<IntlMessages id="message.valid_phone_number" />);
      return {
        status: false,
        error: <IntlMessages id="message.valid_phone_number" />,
      };
    } else if (digits < VALIDATION.MIN_PHONE_NUMBER) {
      callback(<IntlMessages id="message.min_phone_number" />);
      return {
        status: false,
        error: <IntlMessages id="message.min_phone_number" />,
      };
    } else if (digits > VALIDATION.MAX_PHONE_NUMBER) {
      callback(
        <>
          <IntlMessages id={"message.max_value"} />{" "}
          {VALIDATION.MAX_PHONE_NUMBER} <IntlMessages id={"message.max_char"} />
        </>
      );
      return {
        status: false,
        error: (
          <>
            <IntlMessages id={"message.max_value"} />{" "}
            {VALIDATION.MAX_PHONE_NUMBER}{" "}
            <IntlMessages id={"message.max_char"} />
          </>
        ),
      };
    } else {
      callback();
      return { status: true };
    }
  }
  callback();
  return { status: true };
};

export const singleAgeValidateEmptyField =
  (message, isValid) => (rule, value, callback) => {
    const {
      min_number = VALIDATION.MIN_CHAR,
      max_number = VALIDATION.MAX_CHAR,
      isLength,
      min_length = VALIDATION.MIN_CHAR,
      max_length = VALIDATION.MAX_CHAR,
    } = isValid || {};
    if (value) {
      if (!checkPattern(value, PATTERN.WITHOUT_SPACE_BEGINNING)) {
        return callback(<IntlMessages id="message.space_not_allowed" />);
      } else if (value && !checkPattern(value, PATTERN.NUMERIC_CHAR)) {
        return callback(<IntlMessages id="message.valid_age" />);
      }
      // Related to range validation
      if (Number(value) < min_number || Number(value) > max_number) {
        return callback(message || <IntlMessages id="message.valid_input" />);
      } else if (
        isLength &&
        (value?.length < min_length || value?.length > max_length)
      ) {
        return callback(
          <>
            <IntlMessages id={"message.max_value"} /> {max_length}{" "}
            <IntlMessages id={"message.max_char"} />
          </>
        );
      }
    }
  };

export const validateAgeField =
  (message, isValid) => (rule, value, callback) => {
    if (isArray(value)) {
      value.forEach((item, index) => {
        if (item) {
          singleAgeValidateEmptyField(message, isValid)(rule, item, callback);
        }
        if (value.length === index - 1) {
          return callback();
        }
      });
      callback();
    }
    // Single string
    if (value) {
      singleAgeValidateEmptyField(message, isValid)(rule, value, callback);
    }
    callback();
  };

export const singleValidateEmptyField =
  (message, isValid) => (rule, value, callback) => {
    const {
      isSpecialCharAllowed = false,
      isDigits,
      isLength,
      min_number = VALIDATION.MIN_CHAR,
      max_number = VALIDATION.MAX_CHAR,
    } = isValid || {};

    if (value) {
      if (!checkPattern(value, PATTERN.WITHOUT_SPACE_BEGINNING)) {
        return callback(<IntlMessages id="message.space_not_allowed" />);
      } else if (
        value &&
        !isSpecialCharAllowed &&
        !checkPattern(value, PATTERN.NAME_OBJECT)
      ) {
        return callback(<IntlMessages id="message.special_characters" />);
      } else if (
        value &&
        !isDigits &&
        !isSpecialCharAllowed &&
        !checkPattern(value, PATTERN.NAME_ATLEAT_ONE_CHARC)
      ) {
        return callback(<IntlMessages id="message.numeric_characters" />);
      }
      // Related to range validation
      if (
        isDigits &&
        (Number(value) < min_number || Number(value) > max_number)
      ) {
        return callback(message || <IntlMessages id="message.valid_input" />);
      } else if (
        isLength &&
        (value?.length < min_number || value?.length > max_number)
      ) {
        return callback(
          <>
            <IntlMessages id={"message.max_value"} /> {max_number}{" "}
            <IntlMessages id={"message.max_char"} />
          </>
        );
      }
    }
  };

export const validateEmptyField =
  (message, isValid) => (rule, value, callback) => {
    if (isArray(value)) {
      value.forEach((item, index) => {
        if (item) {
          singleValidateEmptyField(message, isValid)(rule, item, callback);
        }
        if (value.length === index - 1) {
          return callback();
        }
      });
      callback();
    }
    // Single string
    if (value) {
      singleValidateEmptyField(message, isValid)(rule, value, callback);
    }
    callback();
  };

export const validateHTMLEmptyField =
  (message, isValid) => (rule, value, callback) => {
    let result = value.replace(PATTERN.HTML_TAG_IGNORE, "");
    if (isArray(result)) {
      result.forEach((item, index) => {
        if (item) {
          singleValidateEmptyField(message, isValid)(rule, item, callback);
        }
        if (result.length === index - 1) {
          return callback();
        }
      });
      callback();
    }
    // Single string
    if (result) {
      singleValidateEmptyField(message, isValid)(rule, result, callback);
    }
    callback();
  };

export const validateCounty = (message) => (rule, value, callback) => {
  if (value) {
    if (value && !checkPattern(value, PATTERN.WITHOUT_SPACE_BEGINNING)) {
      return callback(
        message || <IntlMessages id="message.space_not_allowed" />
      );
    } else if (value && !checkPattern(value, PATTERN.NAME_NUMBER_CHAR)) {
      return callback(<IntlMessages id="message.numeric_characters" />);
    } else if (value?.length > VALIDATION.MAX_CHAR) {
      return callback(
        <>
          <IntlMessages id={"message.max_value"} /> {VALIDATION.MAX_CHAR}{" "}
          <IntlMessages id={"message.max_char"} />
        </>
      );
    }
  }
  callback();
};

export const validateWebUrlAndDomain = (message) => (rule, value, callback) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator

  if (value && !pattern.test(value)) {
    return callback(message || <IntlMessages id="message.valid_url" />);
  }
  callback();
};

export const validateWebUrl = (message) => (rule, value, callback) => {
  if (value && !checkPattern(value, PATTERN.WEB_ULR)) {
    return callback(message || <IntlMessages id="message.valid_url" />);
  }
  callback();
};

export const validateLatLong = (message, isLat) => (rule, value, callback) => {
  if (value) {
    if (!checkPattern(value, PATTERN.NAME_WITHOUT_SPACE)) {
      return callback(<IntlMessages id="message.space_not_allowed" />);
    }
    if (isLat && !checkPattern(Number(value), PATTERN.LAT_VALIDATION)) {
      return callback(message || <IntlMessages id="message.valid_input" />);
    } else if (!checkPattern(Number(value), PATTERN.LONG_VALIDATION)) {
      return callback(message || <IntlMessages id="message.valid_input" />);
    }
    callback();
  }

  callback();
};

export const validateZipCode = (message) => (rule, value, callback) => {
  if (value && !checkPattern(value, PATTERN.ZIP_CODE)) {
    callback(message || <IntlMessages id="message.valid_zip_code" />);
  }
  callback();
};

export const onlyDigitsAllowed = (message) => (rule, value, callback) => {
  if (value && !checkPattern(value, PATTERN.ONLY_DIGITS_ALLOWED)) {
    callback(message || <IntlMessages id="message.only_number_allowed" />);
  }
  callback();
};

export const validatePassword = (message) => (rule, value, callback) => {
  if (value) {
    if (!checkPattern(value, PATTERN.PASSWORD_VALUE)) {
      return callback(message || <IntlMessages id="message.password_format" />);
    }
    if (value?.length < VALIDATION.MIN_PASS_RANGE) {
      return callback(<IntlMessages id="message.password_min" />);
    }
    if (value?.length > VALIDATION.MAX_PASS_RANGE) {
      return callback(
        <>
          <IntlMessages id={"message.max_value"} /> {VALIDATION.MAX_PASS_RANGE}{" "}
          <IntlMessages id={"message.max_char"} />
        </>
      );
    }
  }

  callback();
};

export const disabledDate = (current, fromDisabled) => {
  if (fromDisabled && current < fromDisabled) {
    return current && current < fromDisabled;
  }
  return current && current < moment().startOf("day");
};

export const isBooleanCheck = (value) => {
  return value === "true" || value === true;
};

export const objectLenght = (obj) => {
  return Object.keys(obj)?.length > 0;
};

export const isObjectBoolean = (obj) => {
  return obj ? objectLenght(obj) > 0 : false;
};

export const isArrayBoolean = (value) => {
  return isArray(value) && value?.length > 0;
};

export const generateProviderSelectChildrens = (
  values = [],
  customFunction
) => {
  return (values || []).map((item) => {
    const { id, name, value } = customFunction ? customFunction(item) : item;

    return (
      <Option key={id} value={value !== undefined ? value : id}>
        {name}
      </Option>
    );
  });
};
