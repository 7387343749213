import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  PROVIDER_DATA,
} from "constants/ActionTypes";
import {
  ADD_PROVIDER_LOCAL_DATA,
  DELETE_PROVIDER_LOCAL_DATA,
  AGE_REQUIREMENTS_DROPDOWN,
  PROVIDER_DATA_BY_ID,
  UPDATE_PROVIDER_LOCAL_DATA,
  DOCUMENTS_REQUIRED_DROPDOWN,
  ACCESSIBLITY_DROPDOWN,
  ELIGIBILITIES_DROPDOWN,
  APPLICATION_PROCESS_DROPDOWN,
  LIMITS_PERVISIT_DROPDOWN,
  SET_PROVIDER_API_PARAMS_OBJ,
  FETCH_PROVIDERS_SUCCESS,
  FETCH_PROVIDERS_START,
  FETCH_EXPORT_START,
  FETCH_EXPORT_SUCCESS,
  FETCH_PROVIDER_BY_ID_START,
  FETCH_PROVIDER_BY_ID_SUCCESS,
  PROVIDER_LOADER_FOR_CURD_START,
  PROVIDER_LOADER_FOR_CURD_SUCCESS,
  LIMITS_LANGUAGES_DROPDOWN,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  error: "",
  loadingProviders: false,
  providersCount: "",
  apiParamsObj: {},
  providersData: [],
  providersDataById: {},
  providersLoadingById: false,
  ageRequirementsDropdown: [],
  documentsRequiredDropdown: [],
  accessiblityDropdown: [],
  applicationProcessDropdown: [],
  eligibilitiesDropdown: [],
  limitsPerVisitDropdown: [],
  languagesDropdown: [],
  providersExportLoader: false,
  providersLoaderForCurd: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PROVIDERS_START: {
      return {
        ...state,
        error: "",
        loadingProviders: true,
      };
    }
    case FETCH_PROVIDERS_SUCCESS: {
      return {
        ...state,
        error: "",
        loadingProviders: false,
      };
    }

    case FETCH_START: {
      return {
        ...state,
        error: "",
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: "",
      };
    }
    case FETCH_EXPORT_START: {
      return {
        ...state,
        error: "",
        providersExportLoader: true,
      };
    }
    case FETCH_EXPORT_SUCCESS: {
      return {
        ...state,
        error: "",
        providersExportLoader: false,
      };
    }
    case AGE_REQUIREMENTS_DROPDOWN: {
      return {
        ...state,
        error: "",
        ageRequirementsDropdown: action.payload,
      };
    }
    case DOCUMENTS_REQUIRED_DROPDOWN: {
      return {
        ...state,
        error: "",
        documentsRequiredDropdown: action.payload,
      };
    }
    case ACCESSIBLITY_DROPDOWN: {
      return {
        ...state,
        error: "",
        accessiblityDropdown: action.payload,
      };
    }
    case APPLICATION_PROCESS_DROPDOWN: {
      return {
        ...state,
        error: "",
        applicationProcessDropdown: action.payload,
      };
    }
    case ELIGIBILITIES_DROPDOWN: {
      return {
        ...state,
        error: "",
        eligibilitiesDropdown: action.payload,
      };
    }
    case LIMITS_PERVISIT_DROPDOWN: {
      return {
        ...state,
        error: "",
        limitsPerVisitDropdown: action.payload,
      };
    }
    case LIMITS_LANGUAGES_DROPDOWN: {
      return {
        ...state,
        error: "",
        languagesDropdown: action.payload,
      };
    }

    case ADD_PROVIDER_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        providersCount: state.providersCount + action.count,
        providersData: action.payload,
        categoriesLoading: false,
      };
    }
    case UPDATE_PROVIDER_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        providersData: state.providersData.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        ),
        categoriesLoading: false,
      };
    }
    case PROVIDER_LOADER_FOR_CURD_START: {
      return {
        ...state,
        providersLoadingById: false,
        providersLoaderForCurd: true,
        error: "",
      };
    }
    case PROVIDER_LOADER_FOR_CURD_SUCCESS: {
      return {
        ...state,
        providersLoadingById: false,
        providersLoaderForCurd: false,
        error: "",
      };
    }
    case DELETE_PROVIDER_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        providersCount: state.providersCount - 1,
        providersData: action.payload,
        categoriesLoading: false,
      };
    }
    case SET_PROVIDER_API_PARAMS_OBJ: {
      return {
        ...state,
        apiParamsObj: action.payload,
      };
    }
    case PROVIDER_DATA: {
      return {
        ...state,
        error: "",
        providersCount: action.count,
        providersData: action.payload,
        providersDataById: {},
      };
    }

    case FETCH_PROVIDER_BY_ID_START: {
      return {
        ...state,
        providersLoadingById: true,
        error: "",
      };
    }
    case FETCH_PROVIDER_BY_ID_SUCCESS: {
      return {
        ...state,
        providersLoadingById: false,
        error: "",
      };
    }
    case PROVIDER_DATA_BY_ID: {
      return {
        ...state,
        error: "",
        providersDataById: action.payload,
      };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        providersExportLoader: false,
        error: action.payload,
        providersLoadingById: false,
        providersLoaderForCurd: false,
      };
    }
    default:
      return state;
  }
};
