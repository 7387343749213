import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { setInitUrl, userSignOut } from "../../appRedux/actions/Auth";
import { useApolloClient } from "@apollo/client";

const UserProfile = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { name } = authUser || {};
  const client = useApolloClient();
  const dispatch = useDispatch();

  const logOut = async () => {
    await dispatch(userSignOut({ client }));
    dispatch(setInitUrl("/"));
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => logOut()}>Logout</li>
    </ul>
  );
  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-2 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        {/* <Avatar
          src="https://via.placeholder.com/150x150"
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        /> */}
        <div className="profileImgName">
          <img alt="lo" src="/assets/images/filled-avatar-icon.svg" />
          <span className="gx-avatar-name">
            {name}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </span>
        </div>
      </Popover>
    </div>
  );
};

export default UserProfile;
