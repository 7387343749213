import { combineReducers } from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import { connectRouter } from "connected-react-router";
import Providers from "./Providers";
import Categories from "./Categories";
import Users from "./Users";
import Events from "./Events";
import Requests from "./Requests";
import Offers from "./Offers";
import Counties from './CountyReducer'
// eslint-disable-next-line import/no-anonymous-default-export
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    providers: Providers,
    common: Common,
    categories: Categories,
    users: Users,
    events: Events,
    requests: Requests,
    offers: Offers,
    counties: Counties,
  });
