import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  DELETE_EVENT_LOCAL_DATA,
  ADD_EVENT_LOCAL_DATA,
  UPDATE_EVENT_LOCAL_DATA,
  EVENTS_DATA,
  SET_SORT,
} from "constants/ActionTypes";
import { GET_EVENT_CATEGORIES } from "../../appGraphQl/QUERIES";
import {
  EVENTS_DATA_BY_ID,
  FETCH_EVENTS_BY_ID_START,
  FETCH_EVENTS_BY_ID_SUCCESS,
  FETCH_EVENTS_START,
  FETCH_EVENTS_SUCCESS,
  FETCH_EXPORT_START,
  FETCH_EXPORT_SUCCESS,
  SET_ARCHIVE_REQ,
  SET_EVENTS_API_PARAMS_OBJ,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  error: "",
  eventsLoading: false,
  apiParamsObj: {},
  eventsCount: "",
  eventsData: [],
  eventsCategoryDropdown: [],
  sorterInput: "",
  eventDataById: {},
  eventsExportData: [],
  eventsExportLoader: false,
  eventsLoadingById: false,
  isArchiveReq: false,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        error: "",
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: "",
      };
    }
    case FETCH_EVENTS_START: {
      return {
        ...state,
        error: "",
        eventsLoading: true,
      };
    }
    case FETCH_EVENTS_SUCCESS: {
      return {
        ...state,
        error: "",
        eventsLoading: false,
      };
    }
    case SET_SORT: {
      return {
        ...state,
        sorterInput: { ...action.payload },
      };
    }
    case SET_EVENTS_API_PARAMS_OBJ: {
      return {
        ...state,
        apiParamsObj: action.payload,
      };
    }
    case EVENTS_DATA: {
      return {
        ...state,
        error: "",
        eventsCount: action.count,
        eventDataById: {},
        eventsData: action.payload,
      };
    }
    case FETCH_EVENTS_BY_ID_START: {
      return {
        ...state,
        error: "",
        eventsLoadingById: true,
      };
    }
    case FETCH_EVENTS_BY_ID_SUCCESS: {
      return {
        ...state,
        error: "",
        eventsLoadingById: false,
      };
    }
    case EVENTS_DATA_BY_ID: {
      return {
        ...state,
        error: "",
        eventDataById: action.payload,
      };
    }
    case FETCH_EXPORT_START: {
      return {
        ...state,
        error: "",
        eventsExportLoader: true,
      };
    }
    case FETCH_EXPORT_SUCCESS: {
      return {
        ...state,
        error: "",
        eventsExportLoader: false,
      };
    }

    case GET_EVENT_CATEGORIES: {
      return {
        ...state,
        error: "",
        eventsCategoryDropdown: action.payload,
      };
    }

    case ADD_EVENT_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        eventsCount: state.eventsCount + action.count,
        eventsData: action.payload,
      };
    }
    case UPDATE_EVENT_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        eventsData: state.eventsData.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        ),
      };
    }
    case DELETE_EVENT_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        eventsCount: state.eventsCount - 1,
        eventsData: action.payload,
      };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        error: action.payload,
        eventsExportLoader: false,
        eventsLoadingById: false,
      };
    }
     case SET_ARCHIVE_REQ: {
      return {
        ...state,
        isArchiveReq: action.payload,
      };
    }
    default:
      return state;
  }
};
