import { gql } from "@apollo/client";

export const GET_USERS_BY_TOKEN = gql`
  query {
    me {
      extras {
        holidaysSupported
      }
      user {
        id
        name
        email
        role
        is_password_changed
      }
    }
  }
`;

// Currently Remove SearchTerms params as per the requirement
export const GET_AGE_REQUIREMENTS = gql`
  query getAgeRequirements {
    getAgeRequirements {
      value
    }
  }
`;

export const GET_DOCUMENTS_REQUIRED_DROPDOWN = gql`
  query getDocumentsRequired {
    getDocumentsRequired {
      value
    }
  }
`;

export const GET_ACCESSIBLITY_DROPDOWN = gql`
  query getAccessibility {
    getAccessibility {
      value
    }
  }
`;

export const GET_APPLICATION_PROCESS_DROPDOWN = gql`
  query getApplicationProcess {
    getApplicationProcess {
      value
      options {
        value
      }
    }
  }
`;

export const GET_ELIGIBILITIES_DROPDOWN = gql`
  query getEligibilities {
    getEligibilities {
      value
    }
  }
`;

export const GET_LIMITS_PERVISIT_DROPDOWN = gql`
  query getLimitsPerVisit {
    getLimitsPerVisit {
      value
    }
  }
`;

export const GET_LANGUAGES_DROPDOWN = gql`
  query getLanguages {
    getLanguages{
      value
    }
  }
`;

export const GET_PROVIDERS = gql`
  query getResources(
    $id: Int
    $searchTerm: String
    $lat: Float
    $long: Float
    $categoriesIds: [Int]
    $skip: Int!
    $take: Int!
    $sort: SortInput
    $counties: [String]
  ) {
    getResources(
      id: $id
      searchTerm: $searchTerm
      lat: $lat
      long: $long
      categoriesIds: $categoriesIds
      skip: $skip
      take: $take
      sort: $sort
      counties: $counties
    ) {
      count
      data {
        id
        name
        lat
        long
        email
        information
        service_phone
        site_phone
        notes
        service_email
        website
        documents_required
        age_requirement
        accessibility
        application_process
        limits_per_visit
        languages
        eligibilities
        created_at
        updated_at
        deleted_at
        is_deleted
        is_open
        is_active
        open_days {
          id
          day
          timings
        }
        holiday_hours {
          is_expired
          id
          range {
            start
            end
          }
          timings
        }
        holiday_closures
        address {
          id
          address_line_1
          address_line_2
          county
          city
          state
          zip4
          zip5
        }
        categories {
          id
          name
          is_deleted
        }
        recurring_hours {
          week
          day
          timings
        }
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
query getCategories(
  $skip: Int
  $take: Int
  $searchTerm: String
  $sort: SortInput
) {
  getCategories(
    skip: $skip
    take: $take
    searchTerm: $searchTerm
    sort: $sort
  ) {
    count
    data {
      id
      name
    }
  }
}
`;

export const GET_COUNTY = gql`
query getCounty(
  $searchTerm: String, 
  $skip: Int, 
  $take: Int
  ) {
  getCounty(
    searchTerm: $searchTerm, 
    skip: $skip, 
    take: $take
    ) {
    count
    data {
      value
    }
  }
}`

export const GET_USERS = gql`
  query getUsers(
    $id: Int
    $searchTerm: String
    $role: Role
    $skip: Int!
    $take: Int!
    $sort: SortInput
  ) {
    getUsers(
      id: $id
      searchTerm: $searchTerm
      role: $role
      skip: $skip
      take: $take
      sort: $sort
    ) {
      count
      data {
        id
        name
        email
        role
      }
    }
  }
`;

export const GET_EVENTS = gql`
  query getEvents(
    $id: Int
    $searchTerm: String
    $lat: Float
    $long: Float
    $categoriesIds: [Int]
    $skip: Int!
    $take: Int!
    $sort: SortInput
    $days: [DaysFilterInput]
    $ageRequirement: [String]
    $time: [TimeFilter]
    $isCompleted: Boolean
  ) {
    getEvents(
      id: $id
      searchTerm: $searchTerm
      lat: $lat
      long: $long
      categoriesIds: $categoriesIds
      skip: $skip
      take: $take
      sort: $sort
      days: $days
      ageRequirement: $ageRequirement
      time: $time
      isCompleted: $isCompleted
    ) {
      count
      data {
        id
        name
        phone
        website
        date
        lat
        long
        age_requirement
        recurring_dates
        recurring_data
        event_flyer {
          id
          link
        }
        is_recurring
        created_at
        updated_at
        deleted_at
        is_deleted
        timings
        description
        categories {
          id
          name
        }
        address {
          id
          address_line_1
          address_line_2
          county
          city
          state
          zip4
          zip5
        }
      }
    }
  }
`;

export const GET_EVENT_CATEGORIES = gql`
  query getEventCategories($searchTerm: String) {
    getEventCategories(searchTerm: $searchTerm) {
      id
      name
    }
  }
`;

export const GET_EVENT_PRESIGN = gql`
  query getEventPresign($fileName: String!, $mimeType: String!) {
    getEventPresign(fileName: $fileName, mimeType: $mimeType) {
      url
      key
    }
  }
`;

export const GET_REQUESTS = gql`
  query getApprovalRequests(
    $id: Int
    $searchTerm: String
    $sort: SortInput
    $skip: Int!
    $take: Int!
    $isArchive: Boolean!
    $isFeedback: Boolean
  ) {
    getApprovalRequests(
      id: $id
      searchTerm: $searchTerm
      sort: $sort
      skip: $skip
      take: $take
      isArchive: $isArchive
      isFeedback: $isFeedback
    ) {
      count
      data {
        id
        module_name
        decline_comment
        created_at
        is_approved
        approved_at
        status
        assigned_to {
          id
          name
          email
          role
        }
        field_change
        old_values
        resource_name
        author {
          email
          id
          name
          role
        }
        author_comment
      }
    }
  }
`;

export const GET_OFFERS = gql`
  query getOffers($id: Int, $searchTerm: String, $sort: SortInput) {
    getOffers(id: $id, searchTerm: $searchTerm, sort: $sort) {
      count
      data {
        id
        title
        description
        url
        priority
        titleTranslation
        descriptionTranslation
        location {
          county
          id
        }
      }
    }
  }
`;
