import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      requirePasswordChange
      user {
        id
        email
        name
        reset_token
        reset_token_expires
        role
        updated_at
        is_password_changed
        created_at
      }
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      message
      status
    }
  }
`;

export const INITIAL_RESET_PASS_MUTATION = gql`
mutation InitialPasswordReset(
  $currentPassword: String!
  $newPassword: String!
) {
  initialPasswordReset(
    currentPassword: $currentPassword
    newPassword: $newPassword
  ) {
    accessToken
    requirePasswordChange
    user {
      id
      email
      name
      reset_token
      reset_token_expires
      role
      updated_at
      is_password_changed
      created_at
    }
  }
}  
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      status
      message
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    resetPassword(token: $token, newPassword: $newPassword) {
      user {
        is_password_changed
      }
    }
  }
`;


export const CREATE_RESOURCE_MUTATION = gql`
  mutation createResource(
    $store_name: String!
    $email: String
    $lat: Float!
    $long: Float!
    $notes: String
    $information: String
    $documents_required: [ValueInput]
    $age_requirement: [ValueInput]
    $accessibility: [ValueInput]
    $application_process: [ValueInput]
    $limits_per_visit: [ValueInput]
    $languages: [ValueInput]
    $eligibilities: [ValueInput]
    $service_phone: String
    $address: AddressInput!
    $site_phone: String
    $website: String
    $service_email: String
    $open_days: [HoursInput]
    $holiday_hours: [HolidayHoursInput]
    $holiday_closures: [ClosureDates]
    $categories: [Int!]!
    $recurring_hours: [RecurringHoursInput]
  ) {
    createResource(
      store_name: $store_name
      email: $email
      lat: $lat
      long: $long
      notes: $notes
      information: $information
      documents_required: $documents_required
      age_requirement: $age_requirement
      accessibility: $accessibility
      application_process: $application_process
      limits_per_visit: $limits_per_visit
      languages: $languages
      eligibilities: $eligibilities
      service_phone: $service_phone
      address: $address
      site_phone: $site_phone
      website: $website
      service_email: $service_email
      open_days: $open_days
      holiday_hours: $holiday_hours
      holiday_closures: $holiday_closures
      categories: $categories
      recurring_hours: $recurring_hours
    ) {
      id
      name
      lat
      long
      email
      information
      service_phone
      site_phone
      notes
      service_email
      website
      documents_required
      age_requirement
      accessibility
      application_process
      limits_per_visit
      languages
      eligibilities
      created_at
      updated_at
      deleted_at
      is_deleted
      is_open
      is_active
      open_days {
        id
        day
        timings
      }
      holiday_hours {
        id
        range {
          start
          end
        }
        timings
      }
      holiday_closures
      address {
        id
        address_line_1
        address_line_2
        county
        city
        state
        zip4
        zip5
      }
      categories {
        id
        name
        is_deleted
      }
      recurring_hours {
        week
        day
        timings
      }
    }
  }
`;

export const UPDATE_RESOURCE_MUTATION = gql`
  mutation updateResource(
    $id: Int!
    $store_name: String!
    $email: String
    $lat: Float!
    $long: Float!
    $notes: String
    $information: String
    $documents_required: [ValueInput]
    $age_requirement: [ValueInput]
    $accessibility: [ValueInput]
    $application_process: [ValueInput]
    $limits_per_visit: [ValueInput]
    $languages: [ValueInput]
    $eligibilities: [ValueInput]
    $service_phone: String
    $address: AddressUpdateInput!
    $site_phone: String
    $website: String
    $service_email: String
    $open_days: [UpdateHoursInput]
    $holiday_hours: [UpdateHolidayHoursInput]
    $holiday_closures: [ClosureDates]
    $categories: [Int!]!
    $recurring_hours: [RecurringHoursInput]
  ) {
    updateResource(
      id: $id
      store_name: $store_name
      email: $email
      lat: $lat
      long: $long
      notes: $notes
      information: $information
      documents_required: $documents_required
      age_requirement: $age_requirement
      accessibility: $accessibility
      application_process: $application_process
      limits_per_visit: $limits_per_visit
      languages: $languages
      eligibilities: $eligibilities
      service_phone: $service_phone
      address: $address
      site_phone: $site_phone
      website: $website
      service_email: $service_email
      open_days: $open_days
      holiday_hours: $holiday_hours
      holiday_closures: $holiday_closures
      categories: $categories
      recurring_hours: $recurring_hours
    ) {
      id
      name
      lat
      long
      email
      information
      service_phone
      site_phone
      notes
      service_email
      website
      documents_required
      age_requirement
      accessibility
      application_process
      limits_per_visit
      languages
      eligibilities
      created_at
      updated_at
      deleted_at
      is_deleted
      is_open
      is_active
      open_days {
        id
        day
        timings
      }
      holiday_hours {
        id
        range {
          start
          end
        }
        timings
      }
      holiday_closures
      address {
        id
        address_line_1
        address_line_2
        county
        city
        state
        zip4
        zip5
      }
      categories {
        id
        name
        is_deleted
      }
      recurring_hours {
        week
        day
        timings
      }
    }
  }
`;

export const UPDATE_RESOURCE_MUTATION_TP = gql`
  mutation UpdateResourceTp(
    $updateResourceTpId: Int!
    $openDays: [UpdateHoursInput]
    $holidayHours: [UpdateHolidayHoursInput]
    $holidayClosures: [ClosureDates]
    $recurringHours: [RecurringHoursInput]
  ) {
    updateResourceTp(
      id: $updateResourceTpId
      open_days: $openDays
      holiday_hours: $holidayHours
      holiday_closures: $holidayClosures
      recurring_hours: $recurringHours
    ) {
      id
      name
      lat
      long
      email
      information
      service_phone
      site_phone
      notes
      service_email
      website
      documents_required
      age_requirement
      accessibility
      application_process
      limits_per_visit
      languages
      eligibilities
      created_at
      updated_at
      deleted_at
      is_deleted
      is_open
      is_active
      open_days {
        id
        day
        timings
      }
      holiday_hours {
        id
        range {
          start
          end
        }
        timings
      }
      holiday_closures
      address {
        id
        address_line_1
        address_line_2
        county
        city
        state
        zip4
        zip5
      }
      categories {
        id
        name
        is_deleted
      }
      recurring_hours {
        week
        day
        timings
      }
    }
  }
`;

export const ACTIVE_STATUS_RESOURCE_MUTATION = gql`
  mutation updateResource($id: Int!, $active: Boolean) {
    updateResource(id: $id, active: $active) {
      id
      name
      lat
      long
      email
      information
      service_phone
      site_phone
      notes
      service_email
      website
      documents_required
      age_requirement
      accessibility
      application_process
      limits_per_visit
      languages
      eligibilities
      created_at
      updated_at
      deleted_at
      is_deleted
      is_open
      is_active
      open_days {
        id
        day
        timings
      }
      address {
        id
        address_line_1
        address_line_2
        county
        city
        state
        zip4
        zip5
      }
      categories {
        id
        name
        is_deleted
      }
    }
  }
`;

export const DELETE_RESOURCE_MUTATION = gql`
  mutation deleteResources($deleteResourceIds: [Int]!) {
    deleteResources(deleteResourceIds: $deleteResourceIds) {
      status
      message
    }
  }
`;

export const CREATE_CATEGORIES_MUTATION = gql`
  mutation createCategory($name: String!) {
    createCategory(name: $name) {
      id
      name
    }
  }
`;

export const UPDATE_CATEGORIES_MUTATION = gql`
  mutation updateCategory($id: Int!, $name: String!) {
    updateCategory(id: $id, name: $name) {
      id
      name
    }
  }
`;

export const DELETE_CATEGORIES_MUTATION = gql`
  mutation deleteCategories($categoriesIds: [Int]!) {
    deleteCategories(categoriesIds: $categoriesIds) {
      status
      message
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation createUser(
    $email: String!
    $password: String!
    $name: String
    $role: Role!
  ) {
    createUser(email: $email, password: $password, name: $name, role: $role) {
      user {
        id
        name
        email
        role
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $id: Int!
    $name: String
    $password: String
    $email: String
  ) {
    updateUser(id: $id, name: $name, password: $password, email: $email) {
      id
      name
      email
      role
    }
  }
`;

export const DELETE_USERS_MUTATION = gql`
  mutation deleteUsers($deleteUserIds: [Int]!) {
    deleteUsers(deleteUserIds: $deleteUserIds) {
      status
      message
    }
  }
`;

export const CREATE_EVENT_MUTATION = gql`
  mutation createEvent(
    $name: String!
    $phone: String
    $website: String
    $timings: Timings!
    $categories: [Int!]!
    $date: LocalDate!
    $address: AddressInput!
    $lat: Float!
    $long: Float!
    $description: String
    $age_requirement: [ValueInput]
    $event_flyers: [String]
    $recurrence: [CustomRecurrence]
  ) {
    createEvent(
      name: $name
      phone: $phone
      website: $website
      timings: $timings
      categories: $categories
      date: $date
      address: $address
      lat: $lat
      long: $long
      description: $description
      age_requirement: $age_requirement
      event_flyers: $event_flyers
      recurrence: $recurrence
    ) {
      id
      name
      phone
      website
      date
      lat
      long
      age_requirement
      recurring_dates
      recurring_data
      event_flyer {
        id
        link
      }
      is_recurring
      created_at
      updated_at
      deleted_at
      is_deleted
      timings
      description
      categories {
        id
        name
      }
      address {
        id
        address_line_1
        address_line_2
        county
        city
        state
        zip4
        zip5
      }
    }
  }
`;

export const UPDATE_EVENT_MUTAION = gql`
  mutation updateEvent(
    $id: Int!
    $name: String!
    $phone: String
    $website: String
    $timings: Timings!
    $categories: [Int!]!
    $date: LocalDate!
    $address: AddressInput!
    $lat: Float!
    $long: Float!
    $description: String
    $age_requirement: [ValueInput]
    $event_flyers: [String]
    $recurrence: [CustomRecurrence]
  ) {
    updateEvent(
      id: $id
      name: $name
      phone: $phone
      website: $website
      timings: $timings
      categories: $categories
      date: $date
      address: $address
      lat: $lat
      long: $long
      description: $description
      age_requirement: $age_requirement
      event_flyers: $event_flyers
      recurrence: $recurrence
    ) {
      id
      name
      phone
      website
      date
      lat
      long
      age_requirement
      recurring_dates
      recurring_data
      event_flyer {
        id
        link
      }
      is_recurring
      created_at
      updated_at
      deleted_at
      is_deleted
      timings
      description
      categories {
        id
        name
      }
      address {
        id
        address_line_1
        address_line_2
        county
        city
        state
        zip4
        zip5
      }
    }
  }
`;

export const DELETE_EVENTS_MUTATION = gql`
  mutation deleteEvents($deleteEventIds: [Int!]!) {
    deleteEvents(deleteEventIds: $deleteEventIds) {
      status
      message
    }
  }
`;

export const CREATE_OFFERS_MUTATION = gql`
  mutation createOffer(
    $url: String
    $priority: Int
    $location: [String!]
    $titleTranslation: OffersTranslationInput
    $descriptionTranslation: OffersTranslationInput
  ) {
    createOffer(
      url: $url
      priority: $priority
      location: $location
      titleTranslation: $titleTranslation
      descriptionTranslation: $descriptionTranslation
    ) {
      id
      title
      description
      url
      priority
      titleTranslation
      descriptionTranslation
      location {
        county
        id
      }
    }
  }
`;

export const UPDATE_OFFERS_MUTATION = gql`
  mutation updateOffer(
    $id: Int!
    $url: String
    $priority: Int
    $location: [String!]
    $titleTranslation: OffersTranslationInput
    $descriptionTranslation: OffersTranslationInput
  ) {
    updateOffer(
      id: $id
      url: $url
      priority: $priority
      location: $location
      titleTranslation: $titleTranslation
      descriptionTranslation: $descriptionTranslation
    ) {
      id
      title
      description
      url
      priority
      titleTranslation
      descriptionTranslation
      location {
        county
        id
      }
    }
  }
`;

export const UPDATE_OFFERS_PRIORITY_MUTATION = gql`
  mutation updateOfferPriority($data: [UpdateOfferPriority]) {
    updateOfferPriority(data: $data) {
      id
      title
      description
      url
      priority
    }
  }
`;

export const ANNOUNCEMENT_OFFERS_MUTATION = gql`
  mutation updateOffer($id: Int!) {
    updateOffer(id: $id) {
      id
      title
      description
      url
      priority
    }
  }
`;

export const DELETE_OFFERS_MUTATION = gql`
  mutation deleteOffers($offerDeleteIds: [Int]!) {
    deleteOffers(offerDeleteIds: $offerDeleteIds) {
      status
      message
    }
  }
`;

export const MANAGE_REQUESTS_MUTATION = gql`
  mutation manageRequest(
    $id: Int!
    $state: RequestState!
    $declineComment: String
    $onlyApproval: Boolean!
  ) {
    manageRequest(
      id: $id
      state: $state
      declineComment: $declineComment
      onlyApproval: $onlyApproval
    ) {
      status
      message
    }
  }
`;

export const CREATE_REQUESTS_MUTATION = gql`
  mutation createRequest(
    $moduleName: RequestModule!
    $fieldChange: RequestChange!
    $oldValues: JSON!
    $comment: String
    $assignTo: Int
  ) {
    createRequest(
      moduleName: $moduleName
      fieldChange: $fieldChange
      oldValues: $oldValues
      assignTo: $assignTo
      comment: $comment
    ) {
      id
      module_name
      decline_comment
      created_at
      is_approved
      approved_at
      status
      assigned_to {
        id
        name
        email
        role
      }
      field_change
      old_values
      resource_name
      author_comment
    }
  }
`;

export const UPDATE_REQUESTS_MUTATION = gql`
  mutation manageRequest(
    $id: Int!
    $moduleName: RequestModule
    $fieldChange: RequestChange
    $assignTo: Int
    $comment: String
  ) {
    manageRequest(
      id: $id
      moduleName: $moduleName
      fieldChange: $fieldChange
      assignTo: $assignTo
      comment: $comment
    ) {
      id
      module_name
      decline_comment
      created_at
      is_approved
      approved_at
      status
      assigned_to {
        id
        name
        email
        role
      }
      field_change
      resource_name
      author_comment
    }
  }
`;

export const DELETE_REQUESTS_MUTATION = gql`
  mutation deleteRequests($requestDeleteIds: [Int]!) {
    deleteRequests(requestDeleteIds: $requestDeleteIds) {
      status
      message
    }
  }
`;
