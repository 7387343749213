import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { useApolloClient } from "@apollo/client";
import { getUserByToken, userSignIn } from "../appRedux/actions";
import { validateEmailMessage } from "../util/rules";
import { AUTH_USER_DATA } from '../constants/ActionTypes';
import { InitialResetPassword } from './InitialResetPassword';
import ForgotPasswordModal from './ForgotPasswordModal';

const SignIn = (props) => {
  const client = useApolloClient();
  const [openResetPassModal, setOpenResetPassModal] = useState(false)
   const [openForgotPassModal, setOpenForgotPassModal] = useState(false);

  const dispatch = useDispatch();
  const authUser = useSelector(({ auth }) => auth.authUser);
  const ResetPassUser = ['TRUSTED_PARTNER','VOLUNTEER']
  let resetPassForm;

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleResetPassModalClose = () => {
    if (resetPassForm) resetPassForm.resetFields(); 
    setOpenResetPassModal(false);
  };

   const handleForgotPassModalClose = () => {
     setOpenForgotPassModal(false);
   };

  const onFinish = async (values) => {
    const { status,response } = await dispatch(userSignIn(values, client));
    if(!status) return;
    const token = response;
    if (ResetPassUser.includes(response?.role) && response?.requirePasswordChange === true) {
      setOpenResetPassModal(true);
      return;
    } 
    setOpenResetPassModal(false);    
    dispatch({ type: AUTH_USER_DATA, payload: { ...response } });

    dispatch(getUserByToken(token, client));
  };

  useEffect(() => {
    if (authUser !== null && !(ResetPassUser.includes(authUser?.role) && authUser?.requirePasswordChange === true)) { 
      props.history.push("/");
    }
  }, [authUser, openResetPassModal, props.history]);
 
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="message.valid_email" />,
                  },
                  {
                    validator: validateEmailMessage(
                      <IntlMessages id="message.valid_email" />
                    ),
                  },
                ]}
                name="email"
              >
                <Input placeholder="Enter email" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="message.valid.password" />,
                  },
                ]}
                name="password"
              >
                <Input type="password" placeholder="Enter password" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
                <Button
                  type="link"
                  className="gx-mb-0"
                  onClick={() => setOpenForgotPassModal(true)}
                >
                  <IntlMessages id="app.userAuth.forgotPassword" />
                </Button>
              </Form.Item>
            </Form>
          </div>
          <InfoView />
        </div>
      </div>
      <InitialResetPassword
        isOpen={openResetPassModal}
        onClose={handleResetPassModalClose}
        formRef={(formInstance) => {
          resetPassForm = formInstance;
        }}
      />
      <ForgotPasswordModal
        isOpen={openForgotPassModal}
        onClose={handleForgotPassModalClose}
      />
    </div>
  );
};

export default SignIn;
