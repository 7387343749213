import React, { useState } from "react";
import { Modal, Button, Form, Input } from "antd";
import { userInitialPassReset } from '../appRedux/actions';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { displayMessage } from '../util/renderMethod/displayMessage';
import { API_RES_MESSAGE } from '../constants/Common';
import { FETCH_ERROR, FETCH_SUCCESS } from '../constants/ActionTypes';
import { validatePassword } from '../util/rules';
import IntlMessages from '../util/IntlMessages';

export const InitialResetPassword = ({ isOpen, onClose, formRef }) => {
  const client = useApolloClient();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  
  React.useEffect(() => {
    if (formRef) {
      formRef(form); // Pass form instance to parent component
    }
  }, [form, formRef]);

  const handleFormSubmit = async (values) => {
    try {
      const { status } = await dispatch(
        userInitialPassReset(
          { currentPassword: values.currentPassword, newPassword: values.newPassword },
          client
        )
      );
      if (status) {
        displayMessage(FETCH_SUCCESS, API_RES_MESSAGE.INITIAL_PASS_RESET_SUCESS);
        onClose();
      }
    } catch (err) {
      console.error("Unexpected error during password reset:", err);

    }
  };
  
  return (
    <Modal
      title="Reset Password"
      onCancel={onClose}
      open={isOpen}
      footer={null}
    >
      <p>Please create a personalized password, and make note of it in a safe space. This will be your new password moving forward.</p>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        initialValues={{
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
      >
        <Form.Item
          label="Old Password"
          name="currentPassword"
          rules={[
            { required: true, message: "Please enter your old password." },
          ]}
        >
          <Input.Password placeholder="Enter your old password" />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            { required: true, message: "Please enter your new password." },
            {
              validator: validatePassword(
                <IntlMessages id="message.password_format" />
              ),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && value === getFieldValue("currentPassword")) {
                  return Promise.reject(
                    new Error("New password cannot be the same as the old password.")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password placeholder="Enter your new password" />
        </Form.Item>

        <Form.Item
          label="Confirm New Password"
          name="confirmNewPassword"
          rules={[
            { required: true, message: "Please confirm your new password." },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || value === getFieldValue("newPassword")) {
                  return Promise.resolve(); // Skip validation if empty or matches
                }
                return Promise.reject(
                  new Error("The Confirm passwords does not match.")
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm your new password" />
        </Form.Item>

        <Form.Item>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};
