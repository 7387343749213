import moment from "moment-timezone";
import axios from "axios";
import {
  DEFAULT_DATE,
  DEFAULT_TIME12H,
  DEFAULT_TIME24H,
  DEFAULT_PAGE_LIMIT,
  DEFAULT_PAGE,
  DEFAULT_SCHALUDE,
  ROLE,
  devExportURL,
  stageExportURL,
  prodExportURL,
  FILE_TYPE,
  REQUESTS_VALUES_KEY,
  REQUESTS_EXTRA_KEY,
  LOCAL_DATE,
  RECURRENCE_DATA,
  PATTERN,
} from "../constants/Common";
import { Tag } from "antd";
import { isArrayBoolean, isBooleanCheck, isObjectBoolean } from "./rules";
import { isEmpty, values } from "lodash";
import {
  CaretRightOutlined,
  MinusOutlined,
  WarningOutlined,
} from "@ant-design/icons";

// Convert epoch to human-readable
export const formatDate = (date, format = DEFAULT_DATE) => {
  let isType = typeof date;
  return moment(
    isType === REQUESTS_VALUES_KEY.NUMBER ? Number(date) : date
  ).format(format);
};

// Default time format is for API (default we convert time 12H to 24H)
export const formatTime = (time, format = DEFAULT_TIME24H) => {
  const is24HFormat = moment(time, DEFAULT_TIME24H, true).isValid();
  return moment(time, is24HFormat ? DEFAULT_TIME24H : DEFAULT_TIME12H).format(
    format
  );
};

// Date format for view in Range Picker
export const formatForRangePicker = (
  date = undefined,
  format = DEFAULT_DATE
) => {
  return date ? moment(date) : undefined;
};

// Date format for view in Date Picker
export const formatForDatePicker = (
  date = undefined,
  format = DEFAULT_DATE
) => {
  let isType = typeof date;
  if (isType === REQUESTS_VALUES_KEY.STRING) {
    return moment(date);
  } else {
    return date ? moment(formatDate(date), format) : undefined;
  }
};

// Time format as object to set HTML Element (TimePicker as AM and PM)
export const timeFormatForHtmlElement = (
  time = undefined,
  format = DEFAULT_TIME12H
) => {
  return time ? moment(time, format) : undefined;
};

export const startEndTimeForPicker = (timings = DEFAULT_SCHALUDE) => {
  return (timings || []).map((time, index) => {
    return {
      start: timeFormatForHtmlElement(time.start),
      end: timeFormatForHtmlElement(time.end),
    };
  });
};

export const objectForPayload = (values) => {
  return (values || []).map((item) =>
    item.value ? { value: item.value } : { value: item }
  );
};

export const arrayToOjbect = (values) => {
  let arr = {};
  for (let item in values) {
    arr[values[item]] = values[item];
  }
  return arr;
};

// get key value by key name
export const arrayOfobjKeysValueByKey = (values, key) => {
  let arr = [];
  for (let item in values) {
    if (values[item]) {
      arr.push(values[item][key]);
    }
  }
  return arr;
};

export const ArryObjDropdownToArray = ({ values, strCapi = false }) => {
  let arr = [];
  for (let item in values) {
    if (values[item] && values[item].value) {
      let text = values[item].value.replace(/ /g, "_");
      if (strCapi) {
        arr.push(capitalizeFirstCharString(text));
      } else {
        arr.push(text.toLowerCase());
      }
    }
  }
  return arr;
};

export const objectToArray = (values) => {
  let arr = [];
  for (let item in values) {
    if (values[item]) {
      arr.push(values[item]);
    }
  }
  return arr;
};

export const timingsDataFilters = (values) => {
  for (let item in values) {
    if (isEmpty(values[item]?.start)) {
      return false;
    }
  }
  return true;
};

export const objToList = (objValue = [], key, isType) => {
  return (objValue || []).map((item, index) => {
    if (key) {
      // special case for nested checkbox options
      if (item?.options?.length) {
         const prefix = index > 0 ? ', ' : '';
         const optionsString = item.options.map((opt) => opt.value).join(', ');
         return `${prefix}${item[key]} ( ${optionsString} )`;
      }
      return `${index > 0 ? ", " : ""}${item[key]}`;
    } else {
      if (isType === typeof item && !key && !isArrayBoolean(item)) {
        return objToList(objectToArray(item));
      }
      return `${index > 0 ? ", " : ""}${item}`;
    }
  });
};

//Data reform
// Recurrence Event Payload
export const recurrencePayloadChanges = ({ keys, values, isMonthly }) => {
  return (keys || []).map((keyValue, index) => {
    let temp = {
      repeat: values?.repeat,
      endsOn: values?.endsOn
        ? formatDate(values?.endsOn, LOCAL_DATE)
        : undefined,
    };
    if (isMonthly) {
      temp["repeatOn"] = keyValue?.repeatOn;
      temp["repeatNumberDay"] = keyValue?.repeatNumberDay;
    } else {
      temp["repeatOn"] = keyValue;
    }
    return { ...temp };
  });
};

export const recurrenceReformChanges = (values) => {
  // Backend should keep same key for event recurrence "until" and event req recurrence "repeton" are different
  const {
    number = "",
    repeat = RECURRENCE_DATA.NO_REPEAT,
    until,
  } = values[0] || {};
  let temp = {
    repeat,
    endsOn: formatForDatePicker(until),
  };

  let repeatDay = (values || []).map(
    (recurring, index) => recurring?.day?.value
  );
  temp["repeatOn"] = repeatDay;

  if (repeat === RECURRENCE_DATA.MONTHLY) {
    let monthlyRepeatOn = [];
    for (let key in values) {
      monthlyRepeatOn.push({
        repeatNumberDay: values[key]?.number,
        repeatOn: values[key]?.day.value,
      });
    }
    temp["monthlyRepeatOn"] = monthlyRepeatOn;
    temp["repeatOn"] = [];
  }
  return temp;
};

export const reformValueFromDrodown = ({ values, list }) => {
  let arr = [];
  for (let item in list) {
    let currentItem = values[list[item]];
    let keyValue = capitalizeFirstCharString(list[item]);
    if (currentItem?.value !== undefined && currentItem?.value !== false) {
      arr.push({
        value: keyValue,
        custom: currentItem?.custom,
      });
    }
  }
  return arr;
};

export const formatStartAndEndTimings = ({
  schedule_timing,
  providersDataById,
  key,
}) => {
  let openDay = [];
  for (let item in schedule_timing) {
    let currentDay = schedule_timing[item];
    let dayId = providersDataById[key]?.find((days) => days?.day === item);
    currentDay?.timings &&
      openDay.push({
        day: item,
        id: dayId?.id,
        timings: (currentDay?.timings || []).map((time, index) => {
          return {
            start: formatTime(currentDay?.timings[index]?.start),
            end: formatTime(currentDay?.timings[index]?.end),
          };
        }),
      });
  }
  return openDay;
};

export const deepFormatingObject = ({ schedule_value, key, removedKey }) => {
  delete schedule_value?.__typename;
  let parentArray = [];
  for (let item in schedule_value) {
    let options = {};
    let currentKeyOfItem = schedule_value[item];
    for (let key in currentKeyOfItem) {
      let isKey = removedKey.find((k) => k === key);

      if (isKey) {
        let subObject = currentKeyOfItem[key];
        for (let subKey in subObject) {
          if (subKey === "timings") {
            let timingsObj = subObject[subKey];
            options[subKey] = (timingsObj || []).map((time, index) => {
              return {
                start: formatTime(timingsObj[index]?.start),
                end: formatTime(timingsObj[index]?.end),
              };
            });
          } else {
            options[subKey] = subObject[subKey];
          }
        }
      } else {
        if (key === "range") {
          let dateRangeValue = currentKeyOfItem[key];
          options[key] = {
            start: formatDate(dateRangeValue[0], LOCAL_DATE),
            end: formatDate(dateRangeValue[1], LOCAL_DATE),
          };
        } else {
          options[key] = currentKeyOfItem[key];
        }
      }
    }
    parentArray.push(options);
  }
  return parentArray;
};

export const deepReFormatingForForm = ({ schedule_value, key, addKey }) => {
  let parentArray = [];
  for (let item in schedule_value) {
    let options = {};
    let currentKeyOfItem = { ...schedule_value[item] };
    delete currentKeyOfItem.__typename;

    for (let key in currentKeyOfItem) {
      let isKey = addKey.find((add) => {
        if (add[key]) {
          return add[key];
        }
      });
      if (isObjectBoolean(isKey)) {
        let subObject = currentKeyOfItem[key];
        for (let subKey in isKey) {
          if (subKey === "timings") {
            options[isKey[subKey]] = {
              [subKey]: startEndTimeForPicker(subObject),
            };
          } else {
            options[subKey] = subObject[subKey];
          }
        }
      } else {
        if (key === "range") {
          let dateRangeValue = currentKeyOfItem[key];
          options[key] = [
            formatForRangePicker(dateRangeValue.start),
            formatForRangePicker(dateRangeValue.end),
          ];
        } else {
          options[key] = currentKeyOfItem[key];
        }
      }
    }
    parentArray.push(options);
  }
  return parentArray;
};

export const arrayOfDateFormat = ({ dates }) => {
  let formatDates = [];
  for (let item in dates) {
    let currentDate = dates[item];
    currentDate &&
      formatDates.push({
        startDate: formatDate(currentDate[0], LOCAL_DATE),
        endDate: formatDate(currentDate[1], LOCAL_DATE),
      });
  }
  return formatDates;
};

export const arrayOfDateFormatForForm = ({ dates }) => {
  let formatDates = [];
  for (let item in dates) {
    let currentDate = dates[item];
    currentDate &&
      formatDates.push([
        formatForRangePicker(currentDate?.startDate),
        formatForRangePicker(currentDate?.endDate),
      ]);
  }
  return formatDates;
};

export const filtArrayFromArrayObj = (arr, objArr) =>
  (arr || []).map((item) => objArr.find((value) => value.id === item));

export const setPagination = (
  page = DEFAULT_PAGE,
  pageSize = DEFAULT_PAGE_LIMIT
) => {
  return { skip: pageSize * page - pageSize, take: pageSize };
};

export const setPaginationForDelete = (
  page = DEFAULT_PAGE_LIMIT,
  pageSize = DEFAULT_PAGE_LIMIT
) => {
  return { skip: page - pageSize, take: pageSize };
};

export const getPage = ({ skip, take }) => {
  return parseFloat(skip / take);
};

export const removeSpaceCharacter = (values) => {
  return values.replaceAll("_", " ");
};

export const generateRequestFieldName = (values, module_name) => {
  return values.replace(
    module_name === "resources" ? "resource_" : "event_",
    ""
  );
};

export const addScript = (src) => {
  return new Promise((resolve, reject) => {
    const s = document.createElement("script");

    s.setAttribute("src", src);
    s.addEventListener("load", resolve);
    s.addEventListener("error", reject);

    document.body.appendChild(s);
  });
};

const compIsType = (t, s) => {
  for (let z = 0; z < t.length; ++z) if (t[z] === s) return true;

  return false;
};

export const filterIdAndPriority = (list) => {
  return (list || []).map((value) => ({
    id: value.id,
    priority: value.priority,
  }));
};

export const getValueFromQuery = (query, value, defaultValue = "") => {
  return new URLSearchParams(query).get(value) || defaultValue;
};

// This method helps to update local data with new object and id
export const updateApiResObject = (list, newObj) => {
  return list.map((item) =>
    item.id === newObj.id ? { ...item, ...newObj } : item
  );
};

// This method helps to delete local data with by id
export const getRole = (role) => {
  return ROLE.filter((item) => item.value === role)[0];
};

// This method helps to delete local data with by id
export const filterDeleteData = (list, deleteId) => {
  return (list || []).filter((item) => item?.id !== deleteId);
};

// This method helps to delete local data with by key
export const filterDeleteDataByKey = ({ list, key, keyId }) => {
  return (list || []).filter((item) => item[key] !== keyId);
};

export const addLocalData = ({
  data,
  newData,
  pageLimit = DEFAULT_PAGE_LIMIT,
  firstIn = false,
}) => {
  if (firstIn) {
    return [{ ...newData }, ...data];
  } else {
    if (pageLimit > data?.length) {
      return [{ ...newData }, ...data];
    }
    return data;
  }
};

// We are not using this method but we will use or remove in next PR
export const updateLocalMemory = ({
  cache,
  data,
  fetchedList,
  query,
  variables,
}) => {
  cache.writeQuery({
    query: query,
    variables: { ...variables },
    data: {
      getUsers: {
        count: fetchedList.count + 1,
        data: [...fetchedList.data, { ...data.createUser.user }],
      },
    },
  });
};

export const exportDataReform = (data, reformKeys) => {
  return (data || []).map((item) => {
    const newData = {};
    reformKeys.forEach((keyItem) => {
      const { key, keyValue, accessKey } = keyItem || {};
      const valueData = (item[key] || []).map((value) => `${value[keyValue]}`);
      newData[accessKey] = valueData;
    });
    return { ...item, ...newData };
  });
};

const paramsFormater = (params) => {
  const { sort } = params || {};
  params.sort_column = sort?.column || "";
  params.sort_type = sort?.sortType || "";
  delete params.sort;
  return params;
};
// Futures used for export file due to the backend issue we hold this method.
export const exportFile = async ({ url, params }) => {
  const token = localStorage.getItem("token");
  let newUrl = `${prodExportURL}${FILE_TYPE.CSV}?${url}&is_deleted=false`;
  const temp = paramsFormater(params);
  return await axios.get(newUrl, {
    params: { ...temp },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const exportDownload = ({ url, fileName }) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

// Presign upload image
export const presignUploadOnBucket = async ({ file, link }) => {
  const config = {
    headers: {
      "Content-Type": file.type,
    },
  };
  return await axios.put(link, file, config);
};

// Object to array based on key
export const arrayOfObjectToArray = async ({ list = [], key }) => {
  let arr = [];
  if (isArrayBoolean(list)) {
    for (key in list) {
      arr.push(list[key]?.key);
    }
  }
  return arr;
};

export const textFirstLetterCapital = (sentence) => {
  const words = sentence.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(" ");
};

export const disabledHours = () => {
  const hours = [];
  const currentHour = moment().hour();

  for (let i = currentHour + 1; i <= 24; i++) {
    hours.push(i);
  }

  return hours;
};

export const disabledMinutes = (selectedHour) => {
  const minutes = [];
  const currentMinute = moment().minute();
  if (selectedHour === moment().hour()) {
    for (let i = currentMinute; i <= 60; i++) {
      minutes.push(i);
    }
  }
  return minutes;
};

export const addressFormatted = (place, latLng) => {
  let addrSel,
    placeName,
    placeId = "";
  let country,
    state,
    county,
    postal_code,
    city = null;
  addrSel =
    place.formatted_address !== undefined ? place.formatted_address : "";
  placeName = place.name !== undefined ? place.name : "";
  if (place.address_components !== undefined) {
    let addrComp = place.address_components;
    for (let i = 0; i < addrComp.length; ++i) {
      var typ = addrComp[i].types;
      if (compIsType(typ, "administrative_area_level_1"))
        state = addrComp[i].long_name;
      //store the state
      else if (compIsType(typ, "administrative_area_level_2"))
        county = addrComp[i].long_name;
      //store the county
      else if (compIsType(typ, "locality")) city = addrComp[i].long_name;
      //store the city
      else if (compIsType(typ, "country")) country = addrComp[i].long_name;
      //store the country
      else if (compIsType(typ, "postal_code"))
        postal_code = addrComp[i].long_name; //store the country

      //we can break early if we find all three data
      if (
        state != null &&
        county != null &&
        city != null &&
        country != null &&
        postal_code != null
      )
        break;
    }
  }
  return {
    lat: latLng?.lat,
    lng: latLng?.lng,
    formattedAddress: addrSel,
    placeName: placeName,
    placeId: placeId,
    city: city,
    county: county,
    state: state,
    country: country,
    postal_code: postal_code,
  };
};

export const reformRequests = ({ values, module_name }) => {
  const requests = [];
  const extraKey = {};
  for (let field in values) {
    const fieldName = generateRequestFieldName(field, module_name);
    if (fieldName === "holiday_hours") {
      console.log("Not added");
    } else if (fieldName === "recurrence") {
      let recurrence = values[field];
      requests.push({
        recurrence: {
          name: "repeat",
          warnings: [""],
          value: recurrence.repeat,
          validating: false,
        },
      });
      // delete values
    } else if (
      fieldName === "date" ||
      fieldName === REQUESTS_EXTRA_KEY.HOLIDAY_CLOSURES.key
    ) {
      if (fieldName === REQUESTS_EXTRA_KEY.HOLIDAY_CLOSURES.key) {
        extraKey[fieldName] = {
          index: requests?.length,
          name: fieldName,
        };
        requests.push({
          name: fieldName,
          warnings: [""],
          value: [
            formatForRangePicker(values[field][0]?.startDate),
            formatForRangePicker(values[field][0]?.endDate),
          ],
          validating: false,
        });
      } else {
        requests.push({
          name: fieldName,
          warnings: [""],
          value: formatForDatePicker(formatForDatePicker(values[field])),
          validating: false,
        });
      }
    } else if (fieldName === "address") {
      let address = values[field];
      for (let key in address) {
        let fieldNameKey = generateRequestFieldName(key, module_name);
        requests.push({
          name: fieldNameKey,
          warnings: [""],
          value: address[key],
          validating: false,
        });
      }
    } else {
      requests.push({
        name: fieldName,
        warnings: [""],
        value: values[field],
        validating: false,
      });
    }
  }
  return { res: requests, extraKey: extraKey };
};

// Filter Change Field from Request
export const filterReqChanges = ({ onChangeFields, formValue }) => {
  const fieldsChanges = {};
  for (const [key, val] of Object.entries(onChangeFields)) {
    if (
      key === "county" ||
      key === "city" ||
      key === "state" ||
      key === "zip5"
    ) {
      console.log("removed");
    } else if (key === "address_line_1") {
      fieldsChanges["address"] = {
        address_line_1: formValue.address_line_1,
        address_line_2: formValue.address_line_2,
        county: formValue.county,
        city: formValue.city,
        state: formValue.state,
        zip4: formValue.zip4,
        zip5: formValue.zip5,
      };
      fieldsChanges.lat = Number(formValue.lat);
      fieldsChanges.long = Number(formValue.long);
    } else if (key === "holiday_closures") {
      fieldsChanges["holiday_hours"] = formValue["holiday_hours"];
      fieldsChanges[key] = formValue[key];
    } else {
      fieldsChanges[key] = formValue[key];
    }
  }
  if (!fieldsChanges["age_requirement"] && formValue?.age_requirement.length === 0) {
    fieldsChanges["age_requirement"] = [];
  }
  return fieldsChanges;
};

// Add module Name While Creating Request
export const reformRequestsParams = (values, module_name, extrasValue = {}) => {
  const { is_create = false } = extrasValue || {};
  const options = { [REQUESTS_EXTRA_KEY?.IS_REQUEST.value]: is_create };
  let keys = Object.keys(values || {});
  for (let field of keys) {
    options[`${module_name}_${field}`] = values[field];
  }
  return options;
};

// removed module Name While showing event Request
export const reversReformRequestsParams = (values, module_name) => {
  const options = {};
  let keys = Object.keys(values || {});
  for (let field of keys) {
    const fieldName = generateRequestFieldName(field, module_name);
    options[`${fieldName}`] = values[field];
  }
  return options;
};

// Filter Value From Object By using Key
export const filterValueFromDropdown = (key, array) =>
  array.find((item) => item?.value === key);

// Name isEmpty
export const isEmptyView = ({ value, nan = "null", key, isOld }) => {
  if (isEmpty(value)) {
    return (
      <>
        {isOld && <WarningOutlined />} {nan}
      </>
    );
  }
  if (key === "description") {
    return value.replace(PATTERN.HTML_TAG_IGNORE, "");
  }
  return value;
};

// Request fields view
export const getTagWithValue = ({ name, value, oldValue, key, isNewReq }) => {
  if (key === "resource_holiday_hours") {
    return (
      <Tag>
        {name} <MinusOutlined /> {"view on detail page"}
      </Tag>
    );
  }

  if (key === REQUESTS_EXTRA_KEY?.IS_REQUEST.value) {
    if (isBooleanCheck(value)) {
      return <Tag color="success">New</Tag>;
    }
    return;
  }
  if (!isEmpty(value) || !isEmpty(oldValue)) {
    return (
      <Tag className="custom-tag-div">
        {name} <MinusOutlined />{" "}
        {!isNewReq && (
          <>
            {isEmptyView({ value: oldValue, key: name })}{" "}
            {<CaretRightOutlined />}
          </>
        )}{" "}
        {isEmptyView({ value, key: name, isOld: true })}
      </Tag>
    );
  }
};

export const getValueFiltByType = ({
  isType,
  fieldChanges,
  arrKey,
  isNewReq,
}) => {
  const name = removeSpaceCharacter(
    generateRequestFieldName(fieldChanges?.key, fieldChanges?.module_name)
  );

  switch (isType) {
    case REQUESTS_VALUES_KEY.STRING:
    case REQUESTS_VALUES_KEY.NUMBER: {
      return {
        status: true,
        name,
        tagValue: getTagWithValue({
          ...fieldChanges,
          name,
          key: fieldChanges?.key,
          isNewReq,
        }),
      };
    }
    case REQUESTS_VALUES_KEY.BOOLEAN: {
      return {
        status: true,
        name,
        tagValue: getTagWithValue({
          ...fieldChanges,
          name,
          value: fieldChanges?.value?.toString(),
          oldValue: fieldChanges?.oldValue?.toString(),
          key: fieldChanges?.key,
          isNewReq,
        }),
      };
    }
    case REQUESTS_VALUES_KEY.UNDEFINED: {
      return {
        status: true,
        name,
        tagValue: getTagWithValue({
          ...fieldChanges,
          name,
          value: "",
          oldValue: fieldChanges?.oldValue,
          key: fieldChanges?.key,
          isNewReq,
        }),
      };
    }
    case REQUESTS_VALUES_KEY.OBJECT: {
      let listForValue = isObjectBoolean(fieldChanges?.value)
        ? objectToArray(fieldChanges?.value)
        : fieldChanges?.value;

      let oldListForValue = isObjectBoolean(fieldChanges?.oldValue)
        ? objectToArray(fieldChanges?.oldValue)
        : fieldChanges?.oldValue;
      return {
        status: true,
        name,
        tagValue: getTagWithValue({
          ...fieldChanges,
          name,
          value: isEmpty(listForValue)
            ? []
            : objToList(listForValue, arrKey, isType),
          oldValue: isEmpty(oldListForValue)
            ? []
            : objToList(oldListForValue, arrKey, isType),
          key: fieldChanges?.key,
          isNewReq,
        }),
      };
    }
    default:
      return { status: false, name, tagValue: "" };
  }
};

export const isCharOrNot = (char = "") => {
  if (char === " " || char === ".") return true;
  return false;
};

export const splitContentByWord = (string = "", limit = 60) => {
  string = string || "";
  if (string.length > limit) {
    let newString = string.substr(0, limit);

    let last = newString.length - 1;
    let index = last;
    for (index = last; index > -1; index--) {
      let char = newString.charAt(index);

      if (isCharOrNot(char)) {
        break;
      }
    }

    return newString.substr(0, index) + "...";
  } else {
    return string;
  }
};

export const capitalizeString = (value) => {
  let str = value.replace(/_/g, " ");

  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const capitalizeFirstCharString = (value) => {
  let strg = value.replace(/_/g, " ");
  return strg[0].toUpperCase() + strg.slice(1);
};

export const capitalizeFirstCharStringWhocanGetFood = (value) => {
  let strg = value.replace(/_/g, " ");
  return strg[0].toUpperCase() + strg.slice(1);
};

export const ordinalSuffix = (week) => {
  if (week === 'last') {
    return 'last';
  }

  const j = week % 10,
    k = week % 100;
  if (j === 1 && k !== 11) {
    return week + 'st';
  }
  if (j === 2 && k !== 12) {
    return week + 'nd';
  }
  if (j === 3 && k !== 13) {
    return week + 'rd';
  }
  return week + 'th';
};

//  Function to map items with their values and associated options. for nested checkbox.
export const transformItemsToMappedOptions = (items) => {
  const mappedValues = {};

  items?.forEach((item) => {
    const { value, options } = item;

    // For each item, map the value and its corresponding options
    mappedValues[value] = {
      value: value, // Assign the parent value
      options: options?.map((opt) => opt.value), // Map options to their respective values
    };
  });

  return mappedValues;
};

// Transforms an array of objects containing parent values and nested options into a simplified structure by removing key and label. for nested checkbox.
export const simplifyNestedOptions = (values) => {
  return (
    values?.map((item) => ({
      value: item.value, // Map the parent value
      options: item.options.map((opt) => ({ value: opt.value })), // Map the nested options to only include value
    })) ?? []
  );
};

// Converts an object where each key contains a 'value' and 'options' array into an array of objects. for nested checkbox.
export const convertObjectToArray = (inputObject) => {
  const result = [];

  Object.keys(inputObject).forEach((key) => {
    const item = inputObject[key];

    // Check if the item has a value property (indicating a parent item)
    if (item.value !== undefined) {
      // Create an object for the parent item
      const parentObject = {
        value: item.value,
        options: [],
      };

      // If the parent has options (an array), add them as objects with a value property
      if (item.options && item.options.length > 0) {
        parentObject.options = item.options.map((opt) => ({ value: opt }));
      }

      result.push(parentObject);
    }
  });

  return result;
};