import React from 'react';
import { Button, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import IntlMessages from '../util/IntlMessages';
import { validatePassword } from '../util/rules';
import { resetPassword } from '../appRedux/actions';
import { displayMessage } from '../util/renderMethod/displayMessage';
import { FETCH_ERROR, FETCH_SUCCESS } from '../constants/ActionTypes';
import { API_RES_MESSAGE, ERROR_MESSAGE } from '../constants/Common';
import useQueryParams from '../util/renderMethod/useQueryParams';
import InfoView from '../components/InfoView';

const ResetPassword = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const client = useApolloClient();
  const query = useQueryParams();
  const history = useHistory();
  const token = query.get('token');

  const onSubmit = async (values) => {
    if (!token) {
      displayMessage(FETCH_ERROR, ERROR_MESSAGE.INVALID_RESET_PASS_TOKEN);
      history.push('/signin');
      return;
    }
    const { status } = await dispatch(
      resetPassword({ token: token, newPassword: values.newPassword }, client)
    );
    if (status) {
      displayMessage(FETCH_SUCCESS, API_RES_MESSAGE.PASSWORD_RESET_SUCCESS);
      history.push('/signin');
    }
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <p>
              Please choose a new password for your account. Make sure it’s
              strong and memorable.
            </p>
            <Form
              onFinish={onSubmit}
              form={form}
              layout="vertical"
              name="basic"
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your new password.',
                  },
                  {
                    validator: validatePassword(
                      <IntlMessages id="message.password_format" />
                    ),
                  },
                ]}
              >
                <Input.Password placeholder="Enter your new password" />
              </Form.Item>

              <Form.Item
                label="Confirm New Password"
                name="confirmNewPassword"
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your new password.',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || value === getFieldValue('newPassword')) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('The Confirm passwords does not match.')
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm your new password" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="button.submit" />
                </Button>
              </Form.Item>
            </Form>
          </div>
          <InfoView />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
