import React from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { useApolloClient } from '@apollo/client';
import { useDispatch } from 'react-redux';

import IntlMessages from '../util/IntlMessages';
import { validateEmailMessage } from '../util/rules';
import { forgotPasswordSendEmail } from '../appRedux/actions';
import { displayMessage } from '../util/renderMethod/displayMessage';
import { FETCH_SUCCESS } from '../constants/ActionTypes';
import { API_RES_MESSAGE } from '../constants/Common';

const ForgotPasswordModal = ({ isOpen, onClose }) => {
  const [form] = Form.useForm();
  const client = useApolloClient();
  const dispatch = useDispatch();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleFormSubmit = async (values) => {
    const { status } = await dispatch(
      forgotPasswordSendEmail(values.email, client)
    );
    if (status) {
      displayMessage(FETCH_SUCCESS, API_RES_MESSAGE.FORGOT_PASSWORD_EMAIL_SENT);
      handleClose();
    }
  };

  return (
    <Modal
      title="Forgot Password"
      centered
      onCancel={handleClose}
      open={isOpen}
      footer={null}
    >
      <p>
        A verification email will be sent to the provided address to help you
        reset your password.
      </p>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        initialValues={{
          email: '',
        }}
      >
        <Form.Item
          label="Email"
          rules={[
            {
              required: true,
              message: <IntlMessages id="message.valid_email" />,
            },
            {
              validator: validateEmailMessage(
                <IntlMessages id="message.valid_email" />
              ),
            },
          ]}
          name="email"
        >
          <Input placeholder="Enter valid email" />
        </Form.Item>

        <Form.Item>
          <div>
            <Button type="primary" className="gx-mb-0" htmlType="submit">
              <IntlMessages id="app.userAuth.sendEmail" />
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ForgotPasswordModal;
