import {
  CREATE_REQUESTS_MUTATION,
  DELETE_REQUESTS_MUTATION,
  MANAGE_REQUESTS_MUTATION,
  UPDATE_REQUESTS_MUTATION,
} from "../../appGraphQl/Mutation";
import { GET_REQUESTS } from "../../appGraphQl/QUERIES";
import {
  UPDATE_REQUESTS_LOCAL_DATA,
  DELETE_REQUESTS_LOCAL_DATA,
  FETCH_ERROR,
  FETCH_SUCCESS,
  REQUESTS_DATA,
  FETCH_REQUESTS_START,
  SET_REQUESTS_API_PARAMS_OBJ,
  REQUESTS_DATA_BY_ID,
  PROVIDER_DATA,
  PROVIDER_DATA_BY_ID,
} from "../../constants/ActionTypes";
import {
  filterDeleteData,
  getPage,
  setPaginationForDelete,
  reformRequestsParams,
  recurrenceReformChanges,
  reversReformRequestsParams,
} from "../../util/helper";
import { displayMessage } from "../../util/renderMethod/displayMessage";
import { setPagination } from "../../util/helper";
import { SORT_TYPE } from "../../constants/Common";
import { omit } from "lodash";

export const getFilterObjectForRequests = (existParams = {}, values = {}) => {
  return async (dispatch) => {
    const {
      filter,
      searchTerm,
      sort,
      page,
      pageLimit,
      isArchive = false,
    } = values || {};
    let options = {
      ...existParams,
      ...setPagination(page, pageLimit),
      isArchive,
    };
    let keys = Object.keys(filter || {});
    for (let filter of keys) {
      if (
        values.filter[filter]?.length > 0 ||
        values.filter[filter] === true ||
        values.filter[filter] === false
      ) {
        options[filter] = values.filter[filter];
      }
    }
    options["searchTerm"] = searchTerm;
    if (sort?.column && sort.sortType !== SORT_TYPE?.DEFAULT) {
      options["sort"] = { ...sort };
    } else {
      delete options.sort;
    }
    dispatch({ type: SET_REQUESTS_API_PARAMS_OBJ, payload: values });
    return { status: true, data: { ...options } };
  };
};

export const getRequests = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_REQUESTS_START });
      const { data } = await client.query({
        query: GET_REQUESTS,
        variables: { ...params },
        fetchPolicy: "network-only",
      });

      if (data.getApprovalRequests) {
        dispatch({
          type: REQUESTS_DATA,
          count: data.getApprovalRequests?.count,
          payload: data.getApprovalRequests?.data,
        });
        return { status: true, count: data.getApprovalRequests?.count };
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: data.getApprovalRequests.error,
        });
        return { status: false, error: data.getApprovalRequests.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: false, error: error.message };
    }
  };
};

export const getRequestsById = (
  params = {},
  client,
  { isResourceSet = false, moduleName = "" }
) => {
  return async (dispatch) => {
    try {
      const { data } = await client.query({
        query: GET_REQUESTS,
        variables: { ...params },
      });
      let isReqValue = {
        ...data.getApprovalRequests?.data[0],
        field_change: {
          ...data.getApprovalRequests?.data[0]?.field_change,
          event_recurrence: {
            ...recurrenceReformChanges(
              data.getApprovalRequests?.data[0]?.field_change
                ?.event_recurrence || [{}]
            ),
          },
        },
      };

      if (data?.getApprovalRequests) {
        dispatch({
          type: REQUESTS_DATA_BY_ID,
          payload: { ...isReqValue },
        });
        return { status: true, req_data: { ...isReqValue } };
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: data.getApprovalRequests.error,
        });
        return { status: false, error: data.getApprovalRequests.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: false, error: error.message };
    }
  };
};

export const createRequests = (params = {}, client) => {
  return async (dispatch) => {
    try {
      let reformData = {};
      const { is_create = false } = params || {};

      reformData["fieldChange"] = await reformRequestsParams(
        params?.fieldChange,
        params?.req_key,
        {
          is_create,
        }
      );
      if (is_create) {
        reformData["oldValues"] = {};
      } else {
        reformData["oldValues"] = await reformRequestsParams(
          params?.oldValues,
          params?.req_key
        );
      }
      delete params?.req_key;
      const { data } = await client.mutate({
        mutation: CREATE_REQUESTS_MUTATION,
        variables: {
          ...params,
          ...reformData,
          skip: 0,
          take: 10,
        },
      });
      if (data?.createRequest) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: UPDATE_REQUESTS_LOCAL_DATA,
          payload: data.createRequest,
        });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.createRequest.error });
        return { status: FETCH_ERROR, error: data.createRequest.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: false });
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const updateRequests = (params = {}, client) => {
  return async (dispatch) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_REQUESTS_MUTATION,
        variables: { ...params, skip: 0, take: 10 },
      });
      if (data?.updateRequests) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: UPDATE_REQUESTS_LOCAL_DATA,
          payload: data.updateRequests,
        });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.updateRequests.error });
        return { status: FETCH_ERROR, error: data.updateUser.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const manageRequests = ({ params = {}, variables = {}, client }) => {
  return async (dispatch) => {
    try {
      const { getApprovalRequests } = client.cache.readQuery({
        query: GET_REQUESTS,
        variables: { ...variables },
      });
      const newDataList = filterDeleteData(getApprovalRequests.data, params.id);
      let localUpdatedData = {};
      let isPageSet = false;
      if (newDataList?.length > 0 || getApprovalRequests?.count === 1) {
        localUpdatedData = newDataList;
      } else {
        const { getApprovalRequests } = client.cache.readQuery({
          query: GET_REQUESTS,
          variables: {
            ...variables,
            ...setPaginationForDelete(variables?.skip, variables?.take),
          },
        });
        localUpdatedData = getApprovalRequests?.data;
        isPageSet = true;
      }

      const { data } = await client.mutate({
        mutation: MANAGE_REQUESTS_MUTATION,
        variables: { ...params },
        update(cache, { data }) {
          cache.writeQuery({
            query: GET_REQUESTS,
            variables: { ...variables },
            data: {
              getApprovalRequests: {
                count: getApprovalRequests.count - 1,
                data: [...localUpdatedData],
              },
            },
          });
        },
      });
      if (data?.manageRequest) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: UPDATE_REQUESTS_LOCAL_DATA,
          payload: localUpdatedData,
        });
        displayMessage(FETCH_SUCCESS, data?.manageRequest.message);
        return {
          status: true,
          isPageSet: isPageSet,
          page: getPage({ ...variables }),
        };
      } else {
        dispatch({ type: FETCH_ERROR, payload: false });
        return { status: FETCH_ERROR, error: data?.manageRequest.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      displayMessage(FETCH_ERROR, error.message);
      console.log("Error****:", error.message);
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const deleteRequests = ({ params = {}, variables = {}, client }) => {
  return async (dispatch) => {
    try {
      const { getApprovalRequests } = client.cache.readQuery({
        query: GET_REQUESTS,
        variables: { ...variables },
      });

      const newDataList = filterDeleteData(
        getApprovalRequests.data,
        params.requestDeleteIds[0]
      );
      let localUpdatedData = {};
      let isPageSet = false;
      if (newDataList?.length > 0 || getApprovalRequests?.count === 1) {
        localUpdatedData = newDataList;
      } else {
        const { getApprovalRequests } = client.cache.readQuery({
          query: GET_REQUESTS,
          variables: {
            ...variables,
            ...setPaginationForDelete(variables?.skip, variables?.take),
          },
        });
        localUpdatedData = getApprovalRequests?.data;
        isPageSet = true;
      }
      const { data } = await client.mutate({
        mutation: DELETE_REQUESTS_MUTATION,
        variables: { ...params },
        update(cache, { data }) {
          cache.writeQuery({
            query: GET_REQUESTS,
            variables: { ...variables },
            data: {
              getApprovalRequests: {
                count: getApprovalRequests.count - 1,
                data: [...localUpdatedData],
              },
            },
          });
        },
      });
      if (data?.deleteRequests) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: DELETE_REQUESTS_LOCAL_DATA,
          payload: localUpdatedData,
        });
        displayMessage(FETCH_SUCCESS, data?.deleteRequests.message);
        return { status: isPageSet, page: getPage({ ...variables }) };
      } else {
        dispatch({ type: FETCH_ERROR, payload: false });
        return { status: FETCH_ERROR, error: data.deleteRequests.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      displayMessage(FETCH_ERROR, error.message);
      console.log("Error****:", error.message);
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};
