import {
  CREATE_EVENT_MUTATION,
  DELETE_EVENTS_MUTATION,
  UPDATE_EVENT_MUTAION,
} from "../../appGraphQl/Mutation";
import {
  GET_EVENTS,
  GET_EVENT_CATEGORIES,
  GET_EVENT_PRESIGN,
} from "../../appGraphQl/QUERIES";
import {
  ADD_EVENT_LOCAL_DATA,
  UPDATE_EVENT_LOCAL_DATA,
  DELETE_EVENT_LOCAL_DATA,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  EVENTS_DATA,
  EVENTS_DATA_BY_ID,
  SET_EVENTS_API_PARAMS_OBJ,
  FETCH_EXPORT_START,
  FETCH_EVENTS_START,
  FETCH_EVENTS_BY_ID_START,
  FETCH_EVENTS_BY_ID_SUCCESS,
} from "../../constants/ActionTypes";
import {
  filterDeleteData,
  addLocalData,
  getPage,
  setPaginationForDelete,
  exportFile,
  exportDownload,
  recurrenceReformChanges,
} from "../../util/helper";
import { displayMessage } from "../../util/renderMethod/displayMessage";
import { setPagination } from "../../util/helper";
import { DEFAULT_SORT_BY_TIME, SORT_TYPE } from "../../constants/Common";

export const getEventCategoriesDropdown = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.query({
        query: GET_EVENT_CATEGORIES,
        variables: { ...params },
      });
      if (data.getEventCategories) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: GET_EVENT_CATEGORIES,
          payload: data.getEventCategories,
        });
        return { status: true };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getEventCategories.error });
        return { status: false };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: false };
    }
  };
};

export const getFilterObjectForEvents = (existParams = {}, values = {}) => {
  return async (dispatch) => {
    const { filter, searchTerm, sort, page, pageLimit, isCompleted = false } = values || {};
    let options = { ...existParams, ...setPagination(page, pageLimit), isCompleted };
    let keys = Object.keys(filter || {});
    for (let filter of keys) {
      if (values.filter[filter]?.length > 0) {
        options[filter] = values.filter[filter];
      }
    }
    options["searchTerm"] = searchTerm;
    if (sort?.column && sort.sortType !== SORT_TYPE?.DEFAULT) {
      options["sort"] = { ...sort };
    } else {
      options["sort"] = { ...DEFAULT_SORT_BY_TIME };
    }
    dispatch({ type: SET_EVENTS_API_PARAMS_OBJ, payload: values });
    return { status: true, data: { ...options } };
  };
};

export const getEvents = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_EVENTS_START });
      const { data } = await client.query({
        query: GET_EVENTS,
        variables: { ...params },
      });
      if (data.getEvents) {
        dispatch({
          type: EVENTS_DATA,
          count: data.getEvents?.count,
          payload: data.getEvents?.data,
        });
        return { status: true, count: data.getEvents?.count };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getEvents.error });
        return { status: false, error: data.getEvents.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: false, error: error.message };
    }
  };
};

export const exportEventFile = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_EXPORT_START });
      let url = `type=events`;
      const { data } = await exportFile({
        url,
        params,
      });
      params.sort = [{ ...params.sort }];
      exportDownload({ url: data?.url, fileName: "event-list" });
      return { status: true, count: data };
    } catch (error) {
      console.log("Error****:", error.message);
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({ type: FETCH_ERROR, payload: false });
      return { status: false, error: error.message };
    }
  };
};

export const getEventById = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_EVENTS_BY_ID_START });
      const { data } = await client.query({
        query: GET_EVENTS,
        variables: { ...params },
      });

      if (data.getEvents) {
        dispatch({
          type: EVENTS_DATA_BY_ID,
          payload: {
            ...data.getEvents?.data[0],
            recurring_data: {
              ...recurrenceReformChanges(
                data.getEvents?.data[0]?.recurring_data
              ),
            },
          },
        });
        return {
          status: true,
          data: {
            ...data.getEvents?.data[0],
            recurring_data: {
              ...recurrenceReformChanges(
                data.getEvents?.data[0]?.recurring_data
              ),
            },
          },
        };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getEvents.error });
        return { status: false, error: data.getEvents.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: false, payload: false };
    }
  };
};

export const getEventPresign = (params = {}, client) => {
  return async (dispatch) => {
    try {
      const { data } = await client.query({
        query: GET_EVENT_PRESIGN,
        variables: { ...params },
      });
      if (data.getEventPresign) {
        return {
          status: true,
          data: {
            ...data.getEventPresign,
          },
        };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getEvents.error });
        return { status: false, error: data.getEvents.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: false, payload: false };
    }
  };
};

export const createEvent = ({ params = {}, variables, client }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_EVENTS_BY_ID_START });
      const { getEvents } = client.cache.readQuery({
        query: GET_EVENTS,
        variables: { ...variables },
      });
      const { data } = await client.mutate({
        mutation: CREATE_EVENT_MUTATION,
        variables: { ...params },
        update(cache, { data }) {
          cache.writeQuery({
            query: GET_EVENTS,
            variables: { ...variables },
            data: {
              getEvents: {
                count: getEvents.count + 1,
                data: addLocalData({
                  data: getEvents.data,
                  newData: data.createEvent,
                  pageLimit: params?.take,
                  firstIn: true,
                }),
              },
            },
          });
        },
      });
      if (data?.createEvent) {
        dispatch({
          type: ADD_EVENT_LOCAL_DATA,
          count: 1,
          payload: addLocalData({
            data: getEvents.data,
            newData: data.createEvent,
            pageLimit: params?.take,
            firstIn: true,
          }),
        });
        dispatch({ type: FETCH_EVENTS_BY_ID_SUCCESS });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data?.createEvent.error });
        return { status: FETCH_ERROR, error: data?.createEvent.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: false });
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const updateEvent = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_EVENTS_BY_ID_START });
      const { data } = await client.mutate({
        mutation: UPDATE_EVENT_MUTAION,
        variables: { ...params, skip: 0, take: 10 },
      });
      if (data?.updateEvent) {
        dispatch({
          type: UPDATE_EVENT_LOCAL_DATA,
          payload: {
            ...data.updateEvent,
            recurring_data: {
              ...recurrenceReformChanges(data.updateEvent?.recurring_data),
            },
          },
        });
        dispatch({
          type: EVENTS_DATA_BY_ID,
          payload: {
            ...data.updateEvent,
            recurring_data: {
              ...recurrenceReformChanges(data.updateEvent?.recurring_data),
            },
          },
        });
        dispatch({ type: FETCH_EVENTS_BY_ID_SUCCESS });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.updateEvent.error });
        return { status: FETCH_ERROR, error: data.updateEvent.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const deleteEvent = ({ params = {}, variables = {}, client }) => {
  return async (dispatch) => {
    try {
      const { getEvents } = client.cache.readQuery({
        query: GET_EVENTS,
        variables: { ...variables },
      });

      const newDataList = filterDeleteData(
        getEvents.data,
        params.deleteEventIds[0]
      );
      let localUpdatedData = {};
      let isPageSet = false;
      if (newDataList?.length > 0 || getEvents?.count === 1) {
        localUpdatedData = newDataList;
      } else {
        const { getEvents } = client.cache.readQuery({
          query: GET_EVENTS,
          variables: {
            ...variables,
            ...setPaginationForDelete(variables?.skip, variables?.take),
          },
        });
        localUpdatedData = getEvents?.data;
        isPageSet = true;
      }
      const { data } = await client.mutate({
        mutation: DELETE_EVENTS_MUTATION,
        variables: { ...params },
        update(cache, { data }) {
          cache.writeQuery({
            query: GET_EVENTS,
            variables: { ...variables },
            data: {
              getEvents: {
                count: getEvents.count - 1,
                data: [...localUpdatedData],
              },
            },
          });
        },
      });

      if (data?.deleteEvents) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: DELETE_EVENT_LOCAL_DATA,
          payload: localUpdatedData,
        });
        displayMessage(FETCH_SUCCESS, data?.deleteEvents.message);
        return { status: isPageSet, page: getPage({ ...variables }) };
      } else {
        dispatch({ type: FETCH_ERROR, payload: false });
        return { status: FETCH_ERROR, error: data.deleteEvents.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      displayMessage(FETCH_ERROR, error.message);
      console.log("Error****:", error.message);
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};
