import {
  ANNOUNCEMENT_OFFERS_MUTATION,
  CREATE_OFFERS_MUTATION,
  DELETE_OFFERS_MUTATION,
  UPDATE_OFFERS_MUTATION,
  UPDATE_OFFERS_PRIORITY_MUTATION,
} from "../../appGraphQl/Mutation";
import { GET_OFFERS } from "../../appGraphQl/QUERIES";
import {
  ADD_OFFERS_LOCAL_DATA,
  UPDATE_OFFERS_LOCAL_DATA,
  DELETE_OFFERS_LOCAL_DATA,
  OFFERS_DATA_LIST,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  UPDATE_LOCAL_PRIORITY_DATA,
  EXPORT_DATA,
} from "../../constants/ActionTypes";
import {
  filterIdAndPriority,
  filterDeleteData,
  updateApiResObject,
  exportDataReform,
} from "../../util/helper";
import { displayMessage } from "../../util/renderMethod/displayMessage";

export const getOffers = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.query({
        query: GET_OFFERS,
        variables: { ...params },
      });
      if (data.getOffers) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: OFFERS_DATA_LIST,
          count: data.getOffers?.count,
          payload: data.getOffers?.data,
        });
        return { status: true, count: data.getOffers?.count };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getOffers.error });
        return { status: false, error: data.getOffers.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: false, error: error.message };
    }
  };
};

export const exportOffersResources = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START, exportLoader: true });
      const { data } = await client.query({
        query: GET_OFFERS,
        variables: { ...params },
      });

      if (data.getOffers) {
        dispatch({ type: FETCH_SUCCESS, exportLoader: false });
        const reformData = await exportDataReform(data.getOffers?.data, []);
        dispatch({
          type: EXPORT_DATA,
          payload: reformData,
        });
        return { status: true, resData: reformData };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getOffers?.error });
        return { status: false, error: data.getOffers?.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: false, exportLoader: false });
      console.log("Error****:", error.message);
      return { status: false, error: error.message };
    }
  };
};

export const getOffersS = (params = {}, client) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.query({
        query: GET_OFFERS,
        variables: { ...params },
      });
      if (data.getOffers) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: OFFERS_DATA_LIST,
          count: data.getOffers?.count,
          payload: data.getOffers?.data,
        });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.getOffers.error });
        return { status: FETCH_ERROR, error: data.getOffers.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const createOffer = ({ params = {}, client }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await client.mutate({
        mutation: CREATE_OFFERS_MUTATION,
        variables: { ...params },
        update(cache, { data }) {
          const { getOffers } = cache.readQuery({
            query: GET_OFFERS,
          });
          cache.writeQuery({
            query: GET_OFFERS,
            data: {
              getOffers: {
                count: getOffers.count + 1,
                data: [...getOffers.data, { ...data?.createOffer }],
              },
            },
          });
        },
      });
      if (data?.createOffer) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: ADD_OFFERS_LOCAL_DATA,
          count: 1,
          payload: data.createOffer,
        });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.createOffer.error });
        return { type: FETCH_ERROR, payload: data.createOffer.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const updateOffer = (params = {}, client) => {
  return async (dispatch) => {
    try {
      const { getOffers } = client.cache.readQuery({
        query: GET_OFFERS,
      });
      const { data } = await client.mutate({
        mutation: UPDATE_OFFERS_MUTATION,
        variables: { ...params },
      });
      if (data?.updateOffer) {
        const offersData = updateApiResObject(getOffers.data, data.updateOffer);
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: UPDATE_OFFERS_LOCAL_DATA,
          payload: offersData,
        });
        return { status: FETCH_SUCCESS, offersData: offersData };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.updateOffer.error });
        return { type: FETCH_ERROR, payload: data.updateOffer.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const updateOfferPriority = ({ list = [], isUpdateLocal }, client) => {
  return async (dispatch) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_OFFERS_PRIORITY_MUTATION,
        variables: { data: filterIdAndPriority(list) },
        update(cache, { data }) {
          cache.writeQuery({
            query: GET_OFFERS,
            data: {
              getOffers: {
                count: list?.length,
                data: list,
              },
            },
          });
        },
      });
      if (data?.updateOfferPriority) {
        dispatch({ type: FETCH_SUCCESS });
        isUpdateLocal &&
          dispatch({
            type: UPDATE_LOCAL_PRIORITY_DATA,
            payload: list,
          });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: data.updateOfferPriority.error,
        });
        return { type: FETCH_ERROR, payload: data.updateOfferPriority.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const announcementStatusChanges = (params = {}, client) => {
  return async (dispatch) => {
    try {
      const { data } = await client.mutate({
        mutation: ANNOUNCEMENT_OFFERS_MUTATION,
        variables: { ...params, skip: 0, take: 10 },
      });
      if (data?.updateOffer) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: UPDATE_OFFERS_LOCAL_DATA,
          payload: data.updateOffer,
        });
        return { status: FETCH_SUCCESS };
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.updateOffer.error });
        return { type: FETCH_ERROR, payload: data.updateOffer.error };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      console.log("Error****:", error.message);
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};

export const deleteOffers = (params = {}, client) => {
  return async (dispatch) => {
    try {
      const { getOffers } = client.cache.readQuery({
        query: GET_OFFERS,
      });
      const offersData = filterDeleteData(
        getOffers.data,
        params.offerDeleteIds[0]
      );
      const { data } = await client.mutate({
        mutation: DELETE_OFFERS_MUTATION,
        variables: { ...params },
        update(cache, { data }) {
          cache.writeQuery({
            query: GET_OFFERS,
            data: {
              getOffers: {
                count: getOffers.count - 1,
                data: offersData,
              },
            },
          });
        },
      });

      if (data?.deleteOffers) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: DELETE_OFFERS_LOCAL_DATA,
          payload: offersData,
        });
        displayMessage(FETCH_SUCCESS, data?.deleteOffers.message);
        return { status: FETCH_SUCCESS, offersData: offersData };
      } else {
        dispatch({ type: FETCH_ERROR, payload: false });
        return { status: FETCH_ERROR, payload: false };
      }
    } catch (error) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: FETCH_ERROR, payload: false });
      displayMessage(FETCH_ERROR, error.message);
      console.log("Error****:", error.message);
      return { status: FETCH_ERROR, error: error.message };
    }
  };
};
