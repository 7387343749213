import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  COUNTY_DATA,
  SET_SORT,
  ADD_LOCAL_DATA,
  COUNTY_DROPDOWN,
  UPDATE_COUNTY_LOCAL_DATA,
  DELETE_LOCAL_DATA,
} from "constants/ActionTypes";
import { STORE_LOCAL_COUNTY_FOR_PROVIDER_FILTER } from '../../constants/ActionTypes';

const INIT_STATE = {
  error: "",
  countyLoading: false,
  countyCount: "",
  countyData: [],
  countyDropdownList: [],
  sorterInput: "",
  localCounty:[]
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        error: "",
        countyLoading: true,
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: "",
        countyLoading: false,
      };
    }
    case COUNTY_DROPDOWN: {
      return {
        ...state,
        error: "",
        countyDropdownList: action?.payload?.replaceCounty ? action.payload.counties : [...state.countyDropdownList,...action?.payload?.counties],
        countyLoading: false,
        countyCount: action?.payload?.count,
      };
    }
    case SET_SORT: {
      return {
        ...state,
        sorterInput: { ...action.payload },
      };
    }
    case COUNTY_DATA: {
      return {
        ...state,
        error: "",
        countyCount: action.count,
        countyData: action.payload,
        countyLoading: false,
      };
    }
    case ADD_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        countyCount: state.countyCount + action.count,
        countyData: [...state.countyData, { ...action.payload }],
        countyLoading: false,
      };
    }
    case UPDATE_COUNTY_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        countyData: state.countyData?.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        ),
        countyLoading: false,
      };
    }
    
    case STORE_LOCAL_COUNTY_FOR_PROVIDER_FILTER: {
      return { ...state, localCounty: action.payload }
    }

    case DELETE_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        countyCount: state.countyCount - 1,
        countyData: state.countyData.filter(
          (item) => item.id !== action.payload
        ),
        countyLoading: false,
      };
    }

    case FETCH_ERROR: {
      return {
        ...state,
        error: action.payload,
        countyLoading: false,
      };
    }
    default:
      return state;
  }
};
