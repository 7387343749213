export const devURL = "https://api-dev.communitycompass.info/";
export const devExportURL =
  "https://o7hubpdshd.execute-api.us-east-1.amazonaws.com/dev/";

// STAGE URL
export const stageURL = "https://api-stage.communitycompass.info/";
export const stageExportURL =
  "https://9yavf0k51d.execute-api.us-east-1.amazonaws.com/stage/";

// Prod URL
export const prodURL = "https://api.communitycompass.app/";
export const prodExportURL =
  "https://kzsag4mec0.execute-api.us-east-1.amazonaws.com/prod/";

// for dev and prod Key is restricted to particular domain
export const GOOGLE_MAP_PLACE_SECRET_KEY =
  process.env.NODE_ENV === 'development'
    ?  process.env.REACT_APP_GOOGLE_MAP_PLACE_SECRET_KEY
    : 'AIzaSyBy3MH371PvUqQzqGfFRSeqgkGVsxXZnd4';

export const awsImageBaseURL =
  "https://community-compass-data.s3.amazonaws.com/";

export const DEFAULT_DATE = "MM/DD/YYYY";
export const LOCAL_DATE = "YYYY-MM-DD";
// We used this format for user view only ( AM and PM )
export const DEFAULT_TIME12H = "hh:mm a";
// We used this format for pass value in API (24 H format)
export const DEFAULT_TIME24H = "HH:mm";
export const DEFAULT_TIME_START = "10:00:00";
export const DEFAULT_TIME_END = "13:00:00";

export const WEEK_DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const WEEK_DAYS_DATA = {
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
};

export const WEEKS_OF_MONTH = [
  {
    value: "1",
    name: "1st Week of Month",
  },
  {
    value: "2",
    name: "2nd Week",
  },
  {
    value: "3",
    name: "3rd Week",
  },
  {
    value: "4",
    name: "4th Week",
  },
  {
    value: "5",
    name: "5th Week",
  },
  {
    value: "last",
    name: "Last Week of Month",
  },
];

export const REQUEST_TYPE = [
  { key: "0", value: true, name: "End users" },
  { key: "1", value: false, name: "Volunteers" },
];

export const IS_RECURRENCE_DROPDOWN = [
  { key: "0", value: "NO_REPEAT", name: "Doesn't repeat" },
  { key: "5", value: "REPEAT", name: "Repeat" },
];

export const RECURRENCE_DROPDOWN = [
  { key: "0", value: "NO_REPEAT", name: "Doesn't repeat" },
  // { key: "1", value: "DAILY", name: "Daily" },
  { key: "2", value: "WEEKLY", name: "Weekly" },
  { key: "3", value: "MONTHLY", name: "Monthly" },
  // { key: "4", value: "ANNUALY", name: "Annually" },
  // { key: "5", value: "REPEAT", name: "Repeat" },
];

export const RECURRENCE_DATA = {
  NO_REPEAT: "NO_REPEAT",
  // DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  // ANNUALY: "ANNUALY",
  REPEAT: "REPEAT",
};

export const FILE_TYPE = {
  CSV: "csv",
};

export const FILE_EXPORT_TYPE = {
  PROVIDERS: "resources",
  EVENTS: "events",
};

export const DEFAULT_SCHALUDE = [{ start: "07:00", end: "12:00" }];
export const MAX_SCHALUDE = 3;

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_LIMIT = 10;
export const DEFAULT_RESOURCE_LIMIT = 25;
export const DEFAULT_IMAGE_LIMIT = 5;

export const PATTERN = {
  NAME: /^[A-Za-z- ,’'.`~_"]+$/,
  NAME_OBJECT: /^[A-Za-z- 0-9,،é’'.`_"]+$/,
  NUMERIC_CHAR: /^(?=.*[a-zA-Z0-9 ])(?=.*[a-zA-Z0-9 ])([a-zA-Z- 0-9.-_"+]+)$/,
  NAME_ATLEAT_ONE_CHARC:
    /^(?=.*[a-zA-Z0-9 ])(?=.*[a-zA-Z ])([a-zA-Z- 0-9,،é’'.`_"]+)$/,
  NAME_NUMBER_CHAR: /^(?=.*[a-zA-Z])(?=.*[a-zA-Z ])([a-zA-Z0-9 ]+)$/,
  NUMBER_CHAR_ONLY: /^[A-Za-z0-9 ]+$/,
  AT_LEAST_ONE_DIGIT: /[0-9]+/,
  AT_LEAST_ONE_SPECIAL_CHARACTER: /[\W]+/,
  ONLY_DIGITS_ALLOWED: /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/,
  PHONE_NUMBER_FORMAT:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  PHONE_NUMBER: /^\(?\+?[\d\(\-\s\)]+$/,
  HREF: /href=("|')(.*?)("|')/g,
  EMAIL: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
  // WITHOUT_SPACE_BEGINNING: /^[^\s].*/ ,
  WITHOUT_SPACE_BEGINNING: /^[^\s]+(\s+[^\s]+)*$/,
  NAME_WITHOUT_SPACE:
    /^[a-zA-Z0-9-!@#$&()\_-`.+,/]{1,}(?: [a-zA-Z0-9!@#$&()\ | \ -`.+,/“”" ? * :~%^_=}[ ]+){0,2}$/,
  LAT_VALIDATION: /^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})$/,
  LONG_VALIDATION: /^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})$/,
  LAT_LONG: /^-?[0-9]*[.][0-9]+$/,
  ZIP_CODE: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  ONLY_NUMBER: /^[0-9]*$/,
  WEB_ULR:
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
  PASSWORD_VALUE:
  /^(?=(.*[a-zA-Z]){6,})(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]{8,15}$/,
  HTML_TAG_IGNORE: /(<([^>]+)>)/gi,
};

export const VALIDATION = {
  MIN_PHONE_NUMBER: 10,
  MAX_PHONE_NUMBER: 10,
  MIN_WEB_URL: 6,
  MIN_PASS_RANGE: 5,
  MAX_PASS_RANGE: 15,
  MIN_CHAR: 1,
  MAX_GENERAL_CHAR: 50,
  MAX_CHAR: 150,
  MIN_AGE: 1,
  MAX_AGE: 150,
  MAX_CHAR_INFO: 5000,
  MAX_CHAR_INFO_OFFERFORM : 150,
  MAX_OFFER_TITLE: 50,
};

export const SPLIT_CONTENT_LIMIT = 120; // in characters

export const URL_PARAMS = {
  NEW_RESOURCE_REQUEST: 0,
  NEW_EVENT_REQUEST: 0,
};
export const REQUESTS_STATUS = {
  PENDING: "PENDING",
  APPROVE: "APPROVE",
  DECLINE: "DECLINE",
};

export const REQUESTS_EXTRA_KEY = {
  ID: {
    key: "id",
    name: "id",
  },
  IS_REQUEST: {
    key: "is_create",
    name: "New",
    value: "is_create",
  },
  HOLIDAY_CLOSURES: {
    key: "holiday_closures",
    name: "Holiday Closures",
    value: "holiday_closures",
  },
};

export const REQUESTS_STATUS_LIST = [
  { key: "0", value: "PENDING", name: "Pending" },
  { key: "1", value: "APPROVE", name: "Approve" },
  { key: "2", value: "DECLINE", name: "Decline" },
];

export const REQUESTS_MODULE = {
  resources: {
    name: "Resource",
    module: "resources",
    field: "resource",
    req_key: "resource",
  },
  events: {
    name: "Event",
    module: "eventClasses",
    field: "eventClasse",
    req_key: "event",
  },
};
export const REQUESTS_VALUES_KEY = {
  STRING: "string",
  NUMBER: "number",
  BOOLEAN: "boolean",
  UNDEFINED: "undefined",
  OBJECT: "object",
};

export const ROLE = [
  { key: "0", value: "SUPER_ADMIN", name: "SUPER ADMIN" },
  { key: "1", value: "ADMIN", name: "ADMIN" },
  { key: "2", value: "TRUSTED_PARTNER", name: "TRUSTED PARTNER" },
  { key: "3", value: "VOLUNTEER", name: "VOLUNTEER" },
];

export const ROLE_TYPE = {
  SUPER_ADMIN: {
    value: "SUPER_ADMIN",
    providers: {
      can_view: true,
      can_export: true,
      can_add: true,
      can_delete: true,
      can_req: false,
    },
    events: {
      can_view: true,
      can_export: true,
      can_add: true,
      can_delete: true,
      can_req: false,
    },
    request_module: {
      can_view: true,
      can_export: true,
      can_add: true,
      can_delete: true,
      can_req: false,
    },
    categories: {
      can_view: true,
      can_add: true,
      can_delete: true,
      can_req: false,
    },
    users: {
      can_view: true,
      can_add: true,
      can_delete: true,
      can_req: false,
    },
    offers: {
      can_view: true,
      can_add: true,
      can_delete: true,
      can_req: false,
    },
  },
  ADMIN: {
    value: "ADMIN",
    providers: {
      can_view: true,
      can_add: true,
      can_delete: true,
      can_req: false,
    },
    events: {
      can_view: true,
      can_add: true,
      can_delete: true,
      can_req: false,
    },
    request_module: {
      can_view: true,
      can_export: true,
      can_add: true,
      can_delete: true,
      can_req: false,
    },
    categories: {
      can_view: true,
      can_export: true,
      can_add: true,
      can_delete: true,
      can_req: false,
    },
    users: {
      can_view: true,
      can_export: true,
      can_add: true,
      can_delete: true,
      can_req: false,
    },
    offers: {
      can_view: true,
      can_export: true,
      can_add: true,
      can_delete: true,
      can_req: false,
    },
  },
  TRUSTED_PARTNER: {
    value: "TRUSTED_PARTNER",
    providers: {
      can_view: true,
      can_export: false,
      can_add: true,
      can_delete: true,
      can_req: true,
    },
    events: {
      can_view: true,
      can_export: false,
      can_add: true,
      can_delete: true,
      can_req: true,
    },
    request_module: {
      can_view: true,
      can_export: false,
      can_add: false,
      can_delete: false,
      can_req: true,
    },
  },
  VOLUNTEER: {
    value: "VOLUNTEER",
    providers: {
      can_view: true,
      can_export: false,
      can_add: true,
      can_delete: true,
      can_req: true,
    },
    events: {
      can_view: true,
      can_export: false,
      can_add: true,
      can_delete: true,
      can_req: true,
    },
    request_module: {
      can_view: true,
      can_export: false,
      can_add: false,
      can_delete: false,
      can_req: true,
    },
  },
};

export const SORT_TYPE = {
  ascend: "ASC",
  descend: "DESC",
  DEFAULT: "DEFAULT",
};

export const SORT_TYPE_FULL = {
  ASC: "ascend",
  DESC: "descend",
};

export const DEFAULT_SORT_BY_TIME = {
  column: "created_at",
  sortType: SORT_TYPE.descend,
};
export const API_RES_CODE = {
  FETCH_SUCCESS: 200,
};

export const API_RES_MESSAGE = {
  USER_ADD_SUCCESS: "User added successfully",
  USER_UPDATE_UPDATE: "User updated successfully",
  USER_UPDATE_DELETE: "User deleted successfully",
  PROVIDERS_UPDATE_UPDATE: "Resources updated successfully",
  PROVIDERS_HR_UPDATE: "Resources hours updated successfully",
  PROVIDERS_ADD_UPDATE: "Resources added successfully",
  PROVIDERS_STATUS_UPDATE:
    "Resources active/inactive status updated successfully",
  EVENT_ADD_SUCCESS: "Event added successfully",
  EVENT_UPDATE_UPDATE: "Event updated successfully",
  CATEGORIES_ADD_SUCCESS: "Category added successfully",
  CATEGORIES_UPDATE_UPDATE: "Category updated successfully",
  OFFERS_ADD_SUCCESS: "Offer added successfully",
  OFFERS_UPDATE_UPDATE: "Offer updated successfully",
  OFFERS_PRIORITY_UPDATE_SUCCESS: "Offer priority updated successfully",
  REQUEST_CREATE_UPDATE: "Request created successfully",
  REQUEST_CREATE_DELETE: "Delete request created successfully",
  REQUEST_CREATE_STATUSCHANGE: "Status change request created successfully",
  REQUEST_EMPTY_CHANGES: "Atleast one value from the resources must be edit",
  ACCESS_DENIED: "Access denied",
  INITIAL_PASS_RESET_SUCESS: 'Your intial password reset successfully. Try Sign in again with the new password',
   FORGOT_PASSWORD_EMAIL_SENT:
    'Your reset password link has been sent to your email.',
  PASSWORD_RESET_SUCCESS:
    'Password reset successfully. Try Sign in again with the new password',
};

export const TEXT_WORDS = {
  EXTERNAL: "External",
  INTERNAL: "Internal",
};

export const TEXTS_CONTENT = {
  ANNOUNCEMENT_EXTERNAL_TITLE:
    "Are you sure you want to announcement for external?",
  ANNOUNCEMENT_INTERNAL_TITLE:
    "Are you sure you want to remove announcement for external?",
  ANNOUNCEMENT_CONTENT: "This announcement will affect to the users!",
  DELETE_REQ: "Are you sure you want to create request?",
  DELETE_REQ_CONTENT: "This will permanently delete.",
  DELETE_REQ_TEXT: "Delete Request",
};

export const languageConstant = {
  en : 'english',
  ar : 'arabic',
  my : 'burmese',
  zh : 'chinese',
  fr : 'french',
  cnh: 'hakachin',
  ht: 'halitian',
  kn: 'karen',
  es: 'spanish',
  sw: 'swahili',
  yo: 'yoruba',
}
export const HOLIDAY_HOURS_BY_CATEGORIES = [3, 5];

export const publicPaths = ['/', '/signin', '/signup', '/reset-password'];

export const ERROR_MESSAGE = {
  INVALID_RESET_PASS_TOKEN:
    'Invalid or expired token. Please request a new password reset link.',
};
