import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  DELETE_USERS_LOCAL_DATA,
  ADD_USERS_LOCAL_DATA,
  UPDATE_USERS_LOCAL_DATA,
  USERS_DROPDOWN,
  USERS_DATA,
  SET_SORT,
} from "constants/ActionTypes";
import { SET_USERS_API_PARAMS_OBJ } from "../../constants/ActionTypes";
import { updateApiResObject, filterDeleteData } from "../../util/helper";

const INIT_STATE = {
  error: "",
  usersLoading: false,
  apiParamsObj: {},
  usersCount: "",
  usersData: [],
  usersDropdownList: [],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        error: "",
        usersLoading: true,
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: "",
        usersLoading: false,
      };
    }
    case USERS_DROPDOWN: {
      return {
        ...state,
        error: "",
        usersDropdownList: action.payload,
        usersLoading: false,
      };
    }
    case SET_USERS_API_PARAMS_OBJ: {
      return {
        ...state,
        apiParamsObj: action.payload,
      };
    }
    case USERS_DATA: {
      return {
        ...state,
        error: "",
        usersCount: action.count,
        usersData: action.payload,
        usersLoading: false,
      };
    }
    case ADD_USERS_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        usersCount: state.usersCount + action.count,
        usersData: action.payload,
        usersLoading: false,
      };
    }
    case UPDATE_USERS_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        usersData: updateApiResObject(state.usersData, action.payload),
        usersLoading: false,
      };
    }
    case DELETE_USERS_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        usersCount: state.usersCount - 1,
        usersData: action.payload,
        usersLoading: false,
      };
    }

    case FETCH_ERROR: {
      return {
        ...state,
        error: action.payload,
        usersLoading: false,
      };
    }
    default:
      return state;
  }
};
