import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  OFFERS_DATA_LIST,
  SET_SORT,
  EXPORT_DATA,
  ADD_OFFERS_LOCAL_DATA,
  UPDATE_OFFERS_LOCAL_DATA,
  UPDATE_LOCAL_PRIORITY_DATA,
  DELETE_OFFERS_LOCAL_DATA,
} from "constants/ActionTypes";

const INIT_STATE = {
  error: "",
  offersLoading: false,
  offersCount: "",
  offersData: [],
  sorterInput: "",
  offersExportData: [],
  offersExportLoader: false,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        error: "",
        offersLoading: true,
        offersExportLoader: action?.exportLoader
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: "",
        offersLoading: false,
        offersExportLoader: action?.exportLoader,
      };
    }
    case SET_SORT: {
      return {
        ...state,
        sorterInput: { ...action.payload },
      };
    }
    case OFFERS_DATA_LIST: {
      return {
        ...state,
        error: "",
        offersCount: action.count,
        offersData: action.payload,
        offersLoading: false,
      };
    }
    case ADD_OFFERS_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        offersCount: state.offersCount + action.count,
        offersData: [...state.offersData, { ...action.payload }],
        offersLoading: false,
      };
    }
    case UPDATE_OFFERS_LOCAL_DATA: {
      return {
        ...state,
        error: "",
        offersData: action.payload,
        offersLoading: false,
      };
    }
    case UPDATE_LOCAL_PRIORITY_DATA: {
      return {
        ...state,
        error: "",
        offersData: action.payload,
        offersLoading: false,
      };
    }
    case DELETE_OFFERS_LOCAL_DATA: {
      console.log("offer delete")

      return {
        ...state,
        error: "",
        offersCount: state.offersCount - 1,
        offersData: action.payload,
        offersLoading: false,
      };
    }
    case EXPORT_DATA: {
      return {
        ...state,
        offersExportData: action.payload,
        offersExportLoader: false,
      };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        error: action.payload,
        offersLoading: false,
        offersExportLoader: action?.exportLoader,
      };
    }
    default:
      return state;
  }
};
