import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";

import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";
import client from "./appGraphQl/client";
import { ApolloProvider } from "@apollo/client";

const store = configureStore();

const NextApp = () => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </ApolloProvider>
  </Provider>
);

export default NextApp;
